import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit } from "@mui/icons-material";

import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import BoxDX from "../../layout/boxdx";
import DataGridDX from "../../layout/datagriddx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";

import useCityService from "../../../shared/services/cityservice";
import ItemBox from "../../units_components/itembox";
import TypographyDX from "../../layout/typographydx";
import { InputAdornment } from "@mui/material";
import CheckBoxDX from "../../controls/checkboxdx";
import useWarehouseService from "../../../shared/services/warehouseservice";
import TypeTranslator from "../../../shared/typetranslator";
import ButtonDX from "../../controls/buttondx";
import useWarehouseAreaService from "../../../shared/services/warehouseareaservice";

const WarehouseDetails = (props: any) => {
  const { state } = useLocation();
  const warehouseId = state?.warehouseId;
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { getWarehouseById, addWarehouse, updateWarehouse } =
    useWarehouseService();
  const { getWarehouseAreasByWarehouseId, archiveWarehouseArea } =
    useWarehouseAreaService();
  const { warehouseTypes, getWarehouseType, getStorageModelType } =
    TypeTranslator();
  const areaColumns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    { field: "code", headerName: `${t("Code")}`, flex: 1 },
    {
      field: "storageModelType",
      headerName: `${t("Storage Model")}`,
      flex: 1,
    },
    {
      field: "freeSpaceStorageCapacity",
      headerName: `${t("Space Capacity")}`,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const defaultValues = {
    warehouseId: 0,
    name: "",
    number: "",
    streetAddress: "",
    cityId: null,
    locationCoord: "",
    pocPhone: "",
    pocName: "",
    ownedByUnits: true,
    ownerCompanyName: "",
    ownerPocName: "",
    ownerPocPhone: "",
    totalSpaceInCbm: 0,
    warehouseTypeId: null,
    licenseInfo: "",
  };

  const navigate = useNavigate();

  const { getCities } = useCityService();
  const { setInfo, setError } = useNotificationContext();

  const [warehouseData, setWarehouseData] = useState<any>(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<any>([]);
  const [isFormEditable, setIsFormEditable] = useState(true);

  const [allotedSpaceForAreas, setAllotedSpaceForAreas] = useState<any>(0);

  // Refs for input fields
  const pocNameRef = useRef<HTMLInputElement>(null);
  const pocPhoneRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setWarehouseData({
      ...warehouseData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const [warehouseAreas, setWarehouseAreas] = useState<any[]>([]);

  const validateForm = () => {
    const newErrors: any = {};

    if (!warehouseData.name) {
      newErrors["name"] = t("Name is required");
    }
    if (!warehouseData.number) {
      newErrors["number"] = t("Number is required");
    }
    if (!warehouseData.streetAddress) {
      newErrors["streetAddress"] = t("Address is required");
    }
    if (!warehouseData.cityId) {
      newErrors["cityId"] = t("City is required");
    }
    if (!warehouseData.warehouseTypeId) {
      newErrors["warehouseTypeId"] = t("Warehouse Type is required");
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: any) => {
    if (!isDataChanged) {
      handleClose();
      return;
    }
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);
      const operation = warehouseId
        ? updateWarehouse(warehouseData)
        : addWarehouse(warehouseData);
      operation
        .then(() => {
          warehouseId
            ? setInfo(t("Warehouse updated successfully"))
            : setInfo(t("Warehouse added successfully"));
          handleClose();
        })
        .catch((error: any) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };

  const onEdit = (data: any) => {
    navigate("/warehouseareadetails", {
      state: {
        warehouseId: warehouseId,
        warehouseAreaId: data.id,
        totalSpaceInWarehouse: warehouseData?.totalSpaceInCbm,
        allotedSpaceForAreas: allotedSpaceForAreas,
      },
    });
  };

  const toCreate = () => {
    navigate("/warehouseareadetails", {
      state: {
        warehouseId: warehouseId,
        totalSpaceInWarehouse: warehouseData?.totalSpaceInCbm,
        allotedSpaceForAreas: allotedSpaceForAreas,
      },
    });
  };

  const onArchive = (data: any) => {
    setIsLoading(true);
    archiveWarehouseArea(data.warehouseAreaId)
      .then(() => {
        setInfo(t("Warehouse Area archived successfully"));
        getData();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDropDownData = () => {
    // get data from the server
    const p1 = getCities();
    Promise.all([p1]).then(([cities]) => {
      setCities(
        cities.map((city: any) => ({ text: city.cityName, value: city.cityId }))
      );
    });
  };

  const getData = () => {
    if (warehouseId) {
      setIsLoading(true);
      const p1 = getWarehouseById(warehouseId);
      const p2 = getWarehouseAreasByWarehouseId(warehouseId);
      Promise.all([p1, p2])
        .then(([warehouse, areas]) => {
          setWarehouseData(warehouse);
          // calculate alloted space (freeSpaceStorageCapacity) of all areas
          const totalallotedSpaceForAreas = areas.reduce(
            (acc: number, area: any) => acc + area.freeSpaceStorageCapacity,
            0
          );
          const updatedAreas = areas.map((area: any) => ({
            ...area,
            storageModelType: getStorageModelType(area.storageModelTypeId),
          }));
          setWarehouseAreas(updatedAreas);
          setAllotedSpaceForAreas(totalallotedSpaceForAreas);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getData();
    getDropDownData();
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [warehouseData]);

  return (
    <BoxDX sx={{ width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Name")}
              name="name"
              value={warehouseData.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Number")}
              name="number"
              value={warehouseData.number}
              onChange={handleInputChange}
              error={errors["number"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Address")}
              name="streetAddress"
              value={warehouseData.streetAddress}
              onChange={handleInputChange}
              error={errors["streetAddress"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("City")}
              items={cities}
              name="cityId"
              value={warehouseData.cityId}
              onChange={handleInputChange}
              error={errors["cityId"]}
              InputLabelProps={{
                shrink: warehouseData.cityId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              ref={pocNameRef}
              label={t("POC Name")}
              name="pocName"
              value={warehouseData.pocName}
              onChange={handleInputChange}
              error={errors["pocName"]}
            />
          </GridDX>

          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              ref={pocPhoneRef}
              label={t("POC Phone")}
              name="pocPhone"
              value={warehouseData.pocPhone}
              onChange={handleInputChange}
              error={errors["pocPhone"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>

          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Total Space")}
              name="totalSpaceInCbm"
              value={warehouseData.totalSpaceInCbm}
              onChange={handleInputChange}
              error={errors["totalSpaceInCbm"]}
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <InputAdornment position="end">CBM</InputAdornment>
                ),
              }}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>

          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Warehouse Type")}
              name="warehouseTypeId"
              value={warehouseData.warehouseTypeId}
              onChange={handleInputChange}
              error={errors["warehouseTypeId"]}
              items={warehouseTypes}
              InputLabelProps={{
                shrink: warehouseData.warehouseTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("License Info")}
              name="licenseInfo"
              value={warehouseData.licenseInfo}
              onChange={handleInputChange}
              error={errors["licenseInfo"]}
            />
          </GridDX>

          {!warehouseData.ownedByUnits && (
            <>
              <GridDX item xs={12} md={4}>
                <TextFieldDX
                  disabled={!isFormEditable}
                  label={t("Owner Company Name")}
                  name="ownerCompanyName"
                  value={warehouseData.ownerCompanyName}
                  onChange={handleInputChange}
                  error={errors["ownerCompanyName"]}
                />
              </GridDX>
              <GridDX item xs={12} md={4}>
                <TextFieldDX
                  disabled={!isFormEditable}
                  label={t("Owner POC Name")}
                  name="ownerPocName"
                  value={warehouseData.ownerPocName}
                  onChange={handleInputChange}
                  error={errors["ownerPocName"]}
                />
              </GridDX>
              <GridDX item xs={12} md={4}>
                <TextFieldDX
                  disabled={!isFormEditable}
                  label={t("Owner POC Phone")}
                  name="ownerPocPhone"
                  value={warehouseData.ownerPocPhone}
                  onChange={handleInputChange}
                  error={errors["ownerPocPhone"]}
                  type="number"
                  // Disable spin buttons for number input
                  className="noSpinButtons"
                />
              </GridDX>
            </>
          )}
          <GridDX item xs={12} md={4}>
            <CheckBoxDX
              disabled={!isFormEditable}
              label={t("Owned By Units")}
              name="ownedByUnits"
              checked={warehouseData.ownedByUnits}
              onChange={handleInputChange}
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      {warehouseId && (
        <ItemBox>
          <BoxDX
            sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
          >
            <TypographyDX variant="h5" sx={{}}>
              {t("Areas")}
            </TypographyDX>
            <ButtonDX
              variant="contained"
              color="primary"
              onClick={toCreate}
              disabled={!isFormEditable}
              sx={{ maxWidth: 120 }}
            >
              {t("Add Area")}
            </ButtonDX>
          </BoxDX>

          <DataGridDX
            getRowId={(row: any) => row.warehouseAreaId}
            rows={warehouseAreas}
            columns={areaColumns}
            loading={isLoading}
            disableRowSelectionOnClick={true}
            checkboxSelection={false}
            onRowClick={onEdit}
          />
        </ItemBox>
      )}
    </BoxDX>
  );
};

export default WarehouseDetails;
