import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import { leadStatusColor, DATE_FORMAT } from "../../shared/globals";
import TypeTranslator from "../../shared/typetranslator";
import useCustomerService from "../../shared/services/customerservice";

const Customers = () => {
  const {
    getStorageTypeValue,
    getLeadRequestTypeValue,
    getLeadRequestStatusValue,
    leadRequestStatuses,
    getRentalPeriodValue,
  } = TypeTranslator();

  const { setAddRecordfn } = useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { getCustomers,archiveCustomer } = useCustomerService();
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "fullname",
      headerName: `${t("Full Name")}`,
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: `${t("Phone Number")}`,
      flex: 1,
    },
    {
      field: "email",
      headerName: `${t("Email")}`,
      flex: 1,
    },
    {
      field: "companyName",
      headerName: `${t("Company Name")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getCustomers()
      .then((data) => {
        setRows(data);
        setDataFromApi(data);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveCustomer(id)
      .then(() => {
        setInfo(t("Customer archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const onEdit = (data: any) => {
    navigate(`/customerdetails`, {
      state: {
        customerId: data?.customerId,
      },
    });
  };

  const toCreate = () => {
    navigate("/customerdetails", {
      state: {
        leadId: null,
      },
    });
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({ fullname, phoneNumber, email, companyName }: any) => {
        return (
          fullname?.toLowerCase().includes(value.toLowerCase()) ||
          phoneNumber?.toLowerCase().includes(value.toLowerCase()) ||
          email?.toLowerCase().includes(value.toLowerCase()) ||
          companyName?.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
    getData();
  }, [i18n.language]);
  return (
    <ListPageDX
      listTitle={t("Customers")}
      name={t("Customer")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.customerId}
      isLoading={isLoading}
      onEdit={onEdit}
      editLabel={t("Preview")}
      onDelete={onDelete}
      deleteLabel={t("Archive")}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={true}
      filterByDate={filterByDate}
    />
  );
};

export default Customers;
