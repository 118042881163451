import React, { useState } from "react";
import GridDX from "../components/layout/griddx";
import { t } from "i18next";
import SelectListDX from "../components/controls/selectlistdx";
import TextFieldDX from "../components/controls/textfielddx";
import ButtonDX from "../components/controls/buttondx";
import UnitsAppBar from "../components/business/appbar/units_appbar";
import { Box, InputAdornment, Typography } from "@mui/material";
import { CheckOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import TypographyDX from "../components/layout/typographydx";
import useLeadService from "../shared/services/leadservice";
import { useNotificationContext } from "../context/notificationcontext";
import {
  RegularExpressions,
  rentalPeriods,
  storageTypes,
} from "../shared/globals";

const RequestQuoteForm = () => {
  const { addLead } = useLeadService();
  const navigate = useNavigate();
  const { setError } = useNotificationContext();
  const [requestData, setRequestData] = useState({
    requestDate: new Date().toISOString(),
    fullname: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    requiredStorage: "",
    rentalPeriodMonths: null,
    storageTypeId: null,
    goodsType: "",
    // 1 because it is generated by user
    requestType: 1,
    requestStatus: 1,
    requestSource: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<any>({});
  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    // setIsChanged(true);
    setRequestData({
      ...requestData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const validateForm = () => {
    const newErrors: any = {};
    if (!requestData.fullname) {
      newErrors["fullname"] = t("Name is required");
    }
    if (!requestData.email) {
      newErrors["email"] = t("Email is required");
    } else if (!RegularExpressions.emailAddress.test(requestData.email))
      newErrors["email"] = t("Invalid email address");
    if (!requestData.phoneNumber) {
      newErrors["phoneNumber"] = t("Phone number is required");
    }
    // storage type
    if (!requestData.storageTypeId) {
      newErrors["storageTypeId"] = t("Storage type is required");
    }
    // rental period
    if (!requestData.rentalPeriodMonths) {
      newErrors["rentalPeriodMonths"] = t("Rental period is required");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    addLead(requestData)
      .then((response) => {
        setIsSubmitted(true);
        setTimeout(() => setShowSuccessMessage(true), 500); // Adjust delay as needed
      })
      .catch((error) => {
        console.log("error, ", error);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // Handle form submission logic here (e.g., send data to server)
  };

  return isSubmitted ? (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#ffffff",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "20px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
        zIndex: "9999",
        opacity: showSuccessMessage ? 1 : 0,
        transition: "opacity 1.5s ease",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4">
        Success <CheckOutlined color="primary" />
      </Typography>
      {/* <p>Your quote request has been submitted successfully.</p>
      <p>We will get back to you soon.</p> */}
      <Typography variant="body1" sx={{ my: 1 }}>
        {t("Your quote request has been submitted successfully.")}
      </Typography>
      <Typography variant="body1" sx={{ my: 1 }}>
        {t("We will get back to you soon.")}
      </Typography>
      <ButtonDX
        onClick={() => {
          setIsSubmitted(false);
          setShowSuccessMessage(false);
          navigate("/dashboard");
        }}
      >
        {t("Close")}
      </ButtonDX>
    </div>
  ) : (
    <>
      <UnitsAppBar forQuote />
      <GridDX
        xs={12}
        container
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
          mt: 10,
          height: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <Box
          component="div"
          style={{
            position: "absolute",

            backgroundColor: "#F7F7F7",
            borderRadius: "100%",
            zIndex: -1,
            top: 100,
          }}
          sx={{
            display: { xs: "none", sm: "block" },
            height: { xs: 600, sm: 600, md: 800 },
            width: { xs: 600, sm: 600, md: 800 },
          }}
        />
        <Box
          component="div"
          style={{
            position: "absolute",

            backgroundColor: "#F2F2F2",
            borderRadius: "100%",
            zIndex: -1,
            top: 150,
          }}
          sx={{
            display: { xs: "none", sm: "block" },
            height: { xs: 500, sm: 500, md: 700 },
            width: { xs: 500, sm: 500, md: 700 },
          }}
        />

        <GridDX
          xs={11}
          sm={7}
          md={5}
          container
          sx={{
            my: 2,
          }}
        >
          <GridDX item xs={12} sx={{ zIndex: 1, margin: 0, padding: 0 }}>
            <TypographyDX
              variant="h4"
              sx={{
                mb: 2,
                width: "100%",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                justifyContent: { xs: "center", sm: "center", md: "left" },
              }}
            >
              {t("Request a Quote")}
            </TypographyDX>
          </GridDX>
          <GridDX
            xs={12}
            container
            rowSpacing={2}
            sx={{
              backgroundColor: "#fff",
              zIndex: 1,
              p: { xs: 2, sm: 3, md: 5 },
              mt: 0.5,
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
            }}
          >
            <GridDX item xs={12}>
              <TextFieldDX
                required
                label={t("Name")}
                name="fullname"
                value={requestData.fullname}
                onChange={handleInputChange}
                error={errors["fullname"]}
              />
            </GridDX>

            <GridDX item xs={12}>
              <TextFieldDX
                required
                placeholder="user@test.com"
                label={t("Email")}
                name="email"
                value={requestData.email}
                onChange={handleInputChange}
                error={errors["email"]}
              />
            </GridDX>
            <GridDX item xs={12}>
              <TextFieldDX
                required
                label={t("Phone")}
                name="phoneNumber"
                value={requestData.phoneNumber}
                onChange={handleInputChange}
                error={errors["phoneNumber"]}
                type="number"
                // Disable spin buttons for number input
                className="noSpinButtons"
              />
            </GridDX>
            <GridDX item xs={12}>
              <TextFieldDX
                label={t("Company Name")}
                name="companyName"
                value={requestData.companyName}
                onChange={handleInputChange}
                error={errors["companyName"]}
              />
            </GridDX>
            <GridDX item xs={12}>
              <TextFieldDX
                label={t("Required Storage")}
                name="requiredStorage"
                value={requestData.requiredStorage}
                onChange={handleInputChange}
                error={errors["requiredStorage"]}
                type="number"
                InputProps={{
                  inputProps: { min: 1 },
                  endAdornment: (
                    <InputAdornment position="end">CBM</InputAdornment>
                  ),
                }}
              />
            </GridDX>

            <GridDX item xs={12}>
              <SelectListDX
                label={t("Storage Type")}
                name="storageTypeId"
                items={storageTypes}
                value={requestData.storageTypeId}
                onChange={handleInputChange}
                error={errors["storageTypeId"]}
                InputLabelProps={{
                  shrink: requestData.storageTypeId !== null, // Set to true if there's a value just to handle label position
                }}
              />
            </GridDX>

            <GridDX item xs={12}>
              <SelectListDX
                label={t("Rental Period")}
                name="rentalPeriodMonths"
                items={rentalPeriods}
                value={requestData.rentalPeriodMonths}
                onChange={handleInputChange}
                error={errors["rentalPeriodMonths"]}
                InputLabelProps={{
                  shrink: requestData.rentalPeriodMonths !== null, // Set to true if there's a value just to handle label position
                }}
              />
            </GridDX>

            <GridDX item xs={12}>
              <TextFieldDX
                label={t("Goods Type")}
                name="goodsType"
                value={requestData.goodsType}
                onChange={handleInputChange}
                error={errors["goodsType"]}
              />
            </GridDX>

            <GridDX item xs={12} justifyContent="center">
              <ButtonDX
                fullWidth
                sx={{ my: 2 }}
                disabled={isLoading}
                onClick={handleSubmit}
              >
                {t("Submit")}
              </ButtonDX>
            </GridDX>
          </GridDX>
        </GridDX>
      </GridDX>
    </>
  );
};

export default RequestQuoteForm;
