import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import GridDX from "../components/layout/griddx";
import PageTitle from "../components/layout/pagetitle";
import ButtonDX from "../components/controls/buttondx";
import LoadingButtonDX from "../components/controls/loadingbuttondx";
import BoxDX from "../components/layout/boxdx";
import ConfirmModal from "../components/alerts/confirmmodal";

const DetailsPageTemplate = () => {
  const navigate = useNavigate();

  const [isDataChanged, setIsDataChanged] = useState<any>(null);
  const [cancelfn, setCancelfn] = useState<any>(null);
  const [saveRecordfn, setSaveRecordfn] = useState<any>(null);
  const [isSaving, setIsSaving] = useState(false);

  const [open, setOpen] = useState(false);

  const onCancel = () => {
    // navigate(-1);
    // console.log("in cancel");
    if (isDataChanged) setOpen(true);
    else handleClose();
  };

  const handleClose = () => {
    cancelfn && cancelfn();
    navigate(-1);
    console.log("in close");
    setOpen(false);
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      {open && (
        <ConfirmModal
          open={open}
          onYesClick={handleClose}
          onNoClick={() => setOpen(false)}
        />
      )}
      <GridDX item xs={12} sx={{ alignItems: "center" }}>
        <PageTitle />
        <BoxDX sx={{ flex: 1, textAlign: "end" }}>
          <ButtonDX
            variant="outlined"
            onClick={onCancel}
            sx={{ mr: 2, maxWidth: 120 }}
          >
            {/* {cancelLabel ?? t("Cancel")} */}
            Cancel
          </ButtonDX>
          <LoadingButtonDX
            color="primary"
            onClick={() => saveRecordfn && saveRecordfn()}
            loading={isSaving}
            sx={{ maxWidth: 120 }}
          >
            {/* {saveLabel ?? t("Save")} */}
            Save
          </LoadingButtonDX>
        </BoxDX>
      </GridDX>
      <GridDX item xs={12}>
        <Outlet
          context={{
            setIsDataChanged,
            isDataChanged,
            setCancelfn,
            setSaveRecordfn,
            handleClose,
            setIsSaving,
          }}
        />
      </GridDX>
    </GridDX>
  );
};

export default DetailsPageTemplate;
