import useSecureAPI from "../hooks/usesecureapi";

const useReportService = () => {
  const secureAPI = useSecureAPI();

  const getReportsForCustomer = async (id: number) => {
    const response = await secureAPI.get(`Report/${id}`);
    return response.data;
  };

  return {
    getReportsForCustomer,
  };
};

export default useReportService;
