import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseBayService = () => {
  const secureAPI = useSecureAPI();

  
  const addWarehouseBay = async (data: any) => {
    const response = await secureAPI.post("WarehouseBay", data);
    return response.data;
  };
  const getWarehouseBaysByRowId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseBay/forrow/${id}`);
    return response.data;
  }
  const getWarehouseBayById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseBay/${id}`);
    return response.data;
  }

  const updateWarehouseBay = async (data: any) => {
    const response = await secureAPI.put(`WarehouseBay`, data);
    return response.data;
  }
  const archiveWarehouseBay = async (id: number) => {
    const response = await secureAPI.put(`WarehouseBay/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseBay,
    getWarehouseBaysByRowId,
    getWarehouseBayById,
    updateWarehouseBay,
    archiveWarehouseBay
  };
};

export default useWarehouseBayService;
