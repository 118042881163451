import { useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit } from "@mui/icons-material";
import { useNotificationContext } from "../../../context/notificationcontext";

import BoxDX from "../../layout/boxdx";
import ItemBox from "../../units_components/itembox";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import DataGridDX from "../../layout/datagriddx";
import { InputAdornment } from "@mui/material";
import WarehouseLevelDetailsModal from "./warehouseleveldetailsmodal";
import TypographyDX from "../../layout/typographydx";
import useWarehouseBayService from "../../../shared/services/warehousebayservice";
import useWarehouseLevelService from "../../../shared/services/warehouselevelsservice";
import ButtonDX from "../../controls/buttondx";

const WarehouseBayDetails = (props: any) => {
  const levelColumns: GridColDef[] = [
    {
      field: "number",
      headerName: `${t("Number")}`,
      flex: 1,
    },
    {
      field: "capacity",
      headerName: `${t("Capacity")}`,
      flex: 1,
    },
    {
      field: "palletSize",
      headerName: `${t("Pallet Size")}`,
      flex: 1,
    },
    {
      field: "numberOfPallets",
      headerName: `${t("Number Of Pallets")}`,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];
  const {
    setIsDataChanged,
    setCancelfn,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { addWarehouseBay, updateWarehouseBay, getWarehouseBayById } =
    useWarehouseBayService();
  const { getWarehouseLevelsByBayId, archiveWarehouseLevel } =
    useWarehouseLevelService();

  const { setInfo, setError } = useNotificationContext();
  const { state } = useLocation();
  const warehouseRowId = state?.warehouseRowId;
  const warehouseBayId = state?.warehouseBayId;
  const totalSpaceInRow = state?.totalSpaceInRow;
  const allotedSpaceForBays = state?.allotedSpaceForBays;
  const defaultValues = {
    warehouseRowId: warehouseRowId,
    warehouseBayId: 0,
    number: "",
    capacityInPallets: 0,
  };
  const [bayData, setBayData] = useState<any>(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showLevelDetails, setShowLevelDetails] = useState(false);
  const [levels, setLevels] = useState<any>([]);
  const [warehouseLevelId, setWarehouseLevelId] = useState<any>(null);
  const [previouslyAllotedSpace, setPreviouslyAllotedSpace] = useState<any>(0);
  const [allotedSpaceForLevels, setAllotedSpaceForLevels] = useState<any>(0);
  const [isFormEditable, setIsFormEditable] = useState(true);

  useEffect(() => {
    getData();
    setCancelfn(() => onCancel);
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [bayData]);

  const onCancel = () => {
    setWarehouseLevelId(null);
    setBayData(defaultValues);
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setBayData({
      ...bayData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const getData = () => {
    if (warehouseBayId) {
      setIsLoading(true);
      const p1 = getWarehouseBayById(warehouseBayId);
      const p2 = getWarehouseLevelsByBayId(warehouseBayId);
      Promise.all([p1, p2])
        .then(([bay, levels]) => {
          setBayData(bay);
          setPreviouslyAllotedSpace(bay.capacityInPallets);
          const totalallotedSpaceForLevels = levels.reduce(
            (acc: number, level: any) => acc + level.capacity,
            0
          );
          setAllotedSpaceForLevels(totalallotedSpaceForLevels);
          setLevels(levels);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!bayData.number) {
      newErrors["number"] = t("Please enter number");
    }
    if (!bayData.capacityInPallets) {
      newErrors["capacityInPallets"] = t("Please enter capacity");
    }

    if (warehouseBayId) {
      if (
        totalSpaceInRow &&
        allotedSpaceForBays &&
        totalSpaceInRow -
          (allotedSpaceForBays - parseFloat(previouslyAllotedSpace)) <
          parseFloat(bayData.capacityInPallets)
      ) {
        newErrors["capacityInPallets"] = t(
          `Storage Capacity cannot be more than the remaining space(${
            totalSpaceInRow -
            (allotedSpaceForBays - parseFloat(previouslyAllotedSpace))
          }) in the Bay.`
        );
      }
    } else if (
      totalSpaceInRow &&
      allotedSpaceForBays &&
      totalSpaceInRow - allotedSpaceForBays <
        parseFloat(bayData.capacityInPallets)
    ) {
      newErrors["capacityInPallets"] = t(
        `Storage Capacity cannot be more than the remaining space(${
          totalSpaceInRow - allotedSpaceForBays
        }) in the Bay.`
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (!isDataChanged) {
      handleClose();
      return;
    }
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);
      const operation = warehouseBayId
        ? updateWarehouseBay(bayData)
        : addWarehouseBay(bayData);
      operation
        .then(() => {
          warehouseBayId
            ? setInfo(t("Warehouse Bay updated successfully"))
            : setInfo(t("Warehouse Bay added successfully"));
          handleClose();
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };

  const onEdit = (data: any) => {
    setWarehouseLevelId(data.id);
    setShowLevelDetails(true);
  };
  const toCreate = () => {
    setShowLevelDetails(true);
  };

  const onArchive = (data: any) => {
    setIsLoading(true);
    archiveWarehouseLevel(data.warehouseLevelId)
      .then(() => {
        setInfo(t("Warehouse Level archived successfully"));
        getData();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  };

  const handleCLoseLevelModal = (isChanged: boolean) => {
    setShowLevelDetails(false);
    if (isChanged) {
      getData();
    }
  };

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      {showLevelDetails && (
        <WarehouseLevelDetailsModal
          handleClose={handleCLoseLevelModal}
          warehouseBayId={warehouseBayId}
          warehouseLevelId={warehouseLevelId}
          totalSpaceInBay={bayData.capacityInPallets}
          allotedSpaceForLevels={allotedSpaceForLevels}
        />
      )}
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
            disabled={!isFormEditable}
              label={t("Number")}
              name="number"
              value={bayData.number}
              onChange={handleInputChange}
              error={errors["number"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
            disabled={!isFormEditable}
              label={t("Capacity")}
              name="capacityInPallets"
              value={bayData.capacityInPallets}
              onChange={handleInputChange}
              error={errors["capacityInPallets"]}
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <InputAdornment position="end">Pallets</InputAdornment>
                ),
              }}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      {warehouseBayId && (
        <ItemBox>
          <BoxDX
            sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
          >
            <TypographyDX variant="h5" sx={{}}>
              {t("Levels")}
            </TypographyDX>
            {levels?.length < 5 && (
              <ButtonDX
                variant="contained"
                color="primary"
                onClick={toCreate}
                sx={{ maxWidth: 120 }}
              >
                {t("Add Level")}
              </ButtonDX>
            )}
          </BoxDX>
          <DataGridDX
            getRowId={(row: any) => row.warehouseLevelId}
            rows={levels}
            columns={levelColumns}
            loading={isLoading}
            disableRowSelectionOnClick={true}
            checkboxSelection={false}
            onRowClick={onEdit}
          />
        </ItemBox>
      )}
    </BoxDX>
  );
};

export default WarehouseBayDetails;
