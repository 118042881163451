import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit, Save } from "@mui/icons-material";
import { useNotificationContext } from "../../../context/notificationcontext";

import BoxDX from "../../layout/boxdx";
import ItemBox from "../../units_components/itembox";
import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import SelectListDX from "../../controls/selectlistdx";
import DataGridDX from "../../layout/datagriddx";
import { InputAdornment } from "@mui/material";
import TypographyDX from "../../layout/typographydx";
import TypeTranslator from "../../../shared/typetranslator";
import useWarehouseRowsService from "../../../shared/services/warehouserowservice";
import useWarehouseBayService from "../../../shared/services/warehousebayservice";
import ButtonDX from "../../controls/buttondx";

const WarehouseRowDetails = (props: any) => {
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { addWarehouseRow, updateWarehouseRow, getWarehouseRowById } =
    useWarehouseRowsService();
  const { getWarehouseBaysByRowId, archiveWarehouseBay } =
    useWarehouseBayService();
  const bayColumns: GridColDef[] = [
    {
      field: "number",
      headerName: `${t("Number")}`,
      flex: 1,
    },
    {
      field: "capacityInPallets",
      headerName: `${t("Capacity In Pallets")}`,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const { setInfo, setError } = useNotificationContext();
  const navigate = useNavigate();

  const { state } = useLocation();

  const warehouseRowId = state?.warehouseRowId;
  const warehouseAreaId = state?.warehouseAreaId;
  const totalSpaceInArea = state?.totalSpaceInArea;
  const allotedSpaceForRows = state?.allotedSpaceForRows;
  const { rowModelTypes } = TypeTranslator();

  const defaultValues = {
    warehouseAreaId: warehouseAreaId,
    warehouseRowId: 0,
    number: "",
    rowModelTypeId: null,
    capacityInCbm: 0,
  };

  const [rowData, setRowData] = useState<any>(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [warehouseBays, setWarehouseBays] = useState<any>([]);
  const [previouslyAllotedSpace, setPreviouslyAllotedSpace] = useState<any>(0);
  const [allotedSpaceForBays, setAllotedSpaceForBays] = useState<any>(0);
  const [isFormEditable, setIsFormEditable] = useState(true);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [rowData]);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setRowData({
      ...rowData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!rowData.number) {
      newErrors["number"] = t("Number is required");
    }
    if (!rowData.rowModelTypeId) {
      newErrors["rowModelTypeId"] = t("Row Model is required");
    }
    if (!rowData.capacityInCbm) {
      newErrors["capacityInCbm"] = t("Capacity is required");
    }
    if (warehouseRowId) {
      if (
        totalSpaceInArea &&
        allotedSpaceForRows &&
        totalSpaceInArea -
          (allotedSpaceForRows - parseFloat(previouslyAllotedSpace)) <
          parseFloat(rowData.capacityInCbm)
      ) {
        newErrors["capacityInCbm"] = t(
          `Storage Capacity cannot be more than the remaining space(${
            totalSpaceInArea -
            (allotedSpaceForRows - parseFloat(previouslyAllotedSpace))
          }) in the Area.`
        );
      }
    } else if (
      totalSpaceInArea &&
      allotedSpaceForRows &&
      totalSpaceInArea - allotedSpaceForRows < parseFloat(rowData.capacityInCbm)
    ) {
      newErrors["capacityInCbm"] = t(
        `Storage Capacity cannot be more than the remaining space(${
          totalSpaceInArea - allotedSpaceForRows
        }) in the Area.`
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (!isDataChanged) {
      handleClose();
      return;
    }
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);

      const operation = warehouseRowId
        ? updateWarehouseRow(rowData)
        : addWarehouseRow(rowData);
      operation
        .then(() => {
          warehouseRowId
            ? setInfo(t("Warehouse Row Updated Successfully"))
            : setInfo(t("Warehouse Row Added Successfully"));
          handleClose();
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
          
        });

      // pop those items out which dont have an quotationItemId and but isDeleted flag
    }
  };

  const onEdit = (data: any) => {
    navigate("/warehousebaydetails", {
      state: {
        warehouseBayId: data.id,
        warehouseRowId: warehouseRowId,
        totalSpaceInRow: rowData.capacityInCbm,
        allotedSpaceForBays: allotedSpaceForBays,
      },
    });
  };
  const toCreate = () => {
    navigate("/warehousebaydetails", {
      state: {
        warehouseRowId: warehouseRowId,
        totalSpaceInRow: rowData.capacityInCbm,
        allotedSpaceForBays: allotedSpaceForBays,
      },
    });
  };

  const onArchive = (data: any) => {
    setIsLoading(true);
    archiveWarehouseBay(data.warehouseBayId)
      .then(() => {
        setInfo(t("Warehouse Bay archived successfully"));
        getData();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getData = () => {
    if (warehouseRowId) {
      setIsLoading(true);
      const p1 = getWarehouseRowById(warehouseRowId);
      const p2 = getWarehouseBaysByRowId(warehouseRowId);
      Promise.all([p1, p2])
        .then(([row, bays]) => {
          setRowData(row);
          setPreviouslyAllotedSpace(row.capacityInCbm);
          const totalallotedSpaceForBays = bays.reduce(
            (acc: any, bay: any) => acc + bay.capacityInPallets,
            0
          );
          setAllotedSpaceForBays(totalallotedSpaceForBays);
          setWarehouseBays(bays);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Number")}
              name="number"
              value={rowData.number}
              onChange={handleInputChange}
              error={errors["number"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Row Model")}
              name="rowModelTypeId"
              value={rowData.rowModelTypeId}
              items={rowModelTypes}
              onChange={handleInputChange}
              error={errors["rowModelTypeId"]}
              InputLabelProps={{
                shrink: rowData.rowModelTypeId !== null,
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Capacity")}
              name="capacityInCbm"
              value={rowData.capacityInCbm}
              onChange={handleInputChange}
              error={errors["capacityInCbm"]}
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <InputAdornment position="end">CBM</InputAdornment>
                ),
              }}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      {warehouseRowId && (
        <ItemBox>
          <BoxDX
            sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
          >
            <TypographyDX variant="h5" sx={{}}>
              {t("Bays")}
            </TypographyDX>
            {warehouseBays?.length < 15 && (
              <ButtonDX
                variant="contained"
                color="primary"
                onClick={toCreate}
                sx={{ maxWidth: 120 }}
              >
                {t("Add Bay")}
              </ButtonDX>
            )}
          </BoxDX>
          <DataGridDX
            getRowId={(row: any) => row.warehouseBayId}
            rows={warehouseBays}
            columns={bayColumns}
            loading={isLoading}
            disableRowSelectionOnClick={true}
            checkboxSelection={false}
            onRowClick={onEdit}
          />
        </ItemBox>
      )}
    </BoxDX>
  );
};

export default WarehouseRowDetails;
