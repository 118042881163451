import useSecureAPI from "../hooks/usesecureapi";

const useDashboardService = () => {
  const secureAPI = useSecureAPI();

  const getOrderCount = async () => {
    const response = await secureAPI.get(`Dashboard/orderCount`);
    return response.data;
  };

  const getCustomerCount = async () => {
    const response = await secureAPI.get(`Dashboard/customerCount`);
    return response.data;
  };

  const getGoodsSummary = async () => {
    const response = await secureAPI.get(`Dashboard/goodsSummary`);
    return response.data;
  };

  const getStorageSummary = async () => {
    const response = await secureAPI.get(`Dashboard/storageSummary`);
    return response.data;
  };

  const getExpiringItemsCount = async () => {
    const response = await secureAPI.get(`Dashboard/expiringItemsCount`);
    return response.data;
  };

  const getExpiringContracts = async () => {
    const response = await secureAPI.get(`Dashboard/expiringContracts`);
    return response.data;
  };

  return {
    getOrderCount,
    getCustomerCount,
    getGoodsSummary,
    getStorageSummary,
    getExpiringItemsCount,
    getExpiringContracts,
  };
};

export default useDashboardService;
