import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseRowsService = () => {
  const secureAPI = useSecureAPI();

  
  const addWarehouseRow = async (data: any) => {
    const response = await secureAPI.post("WarehouseRow", data);
    return response.data;
  };
  const getWarehouseRowsByAreaId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseRow/forarea/${id}`);
    return response.data;
  }
  const getWarehouseRowById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseRow/${id}`);
    return response.data;
  }

  const updateWarehouseRow = async (data: any) => {
    const response = await secureAPI.put(`WarehouseRow`, data);
    return response.data;
  }
  const archiveWarehouseRow = async (id: number) => {
    const response = await secureAPI.put(`WarehouseRow/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseRow,
    getWarehouseRowsByAreaId,
    getWarehouseRowById,
    updateWarehouseRow,
    archiveWarehouseRow
  };
};

export default useWarehouseRowsService;
