import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { Archive, Edit } from "@mui/icons-material";

import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import TextFieldDX from "../../controls/textfielddx";
import BoxDX from "../../layout/boxdx";
import DataGridDX from "../../layout/datagriddx";
import SelectListDX from "../../controls/selectlistdx";
import ItemBox from "../../units_components/itembox";
import TypographyDX from "../../layout/typographydx";
import TypeTranslator from "../../../shared/typetranslator";
import useWarehouseAreaService from "../../../shared/services/warehouseareaservice";
import ButtonDX from "../../controls/buttondx";
import useWarehouseRowsService from "../../../shared/services/warehouserowservice";
import { InputAdornment } from "@mui/material";

const WarehouseAreaDetails = (props: any) => {
  const { storageModelTypes, getRowModelType } = TypeTranslator();

  const rowColumns: GridColDef[] = [
    {
      field: "number",
      headerName: `${t("Number")}`,
      flex: 1,
    },
    {
      field: "capacityInCbm",
      headerName: `${t("Capacity In CBM")}`,
      flex: 1,
    },
    {
      field: "rowModelType",
      headerName: `${t("Row Model Type")}`,
      flex: 1,
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { state } = useLocation();
  const warehouseId = state?.warehouseId;
  const warehouseAreaId = state?.warehouseAreaId;
  const totalSpaceInWarehouse = state?.totalSpaceInWarehouse;
  const allotedSpaceForAreas = state?.allotedSpaceForAreas;

  const { setInfo, setError } = useNotificationContext();
  const navigate = useNavigate();
  const { addWarehouseArea, updateWarehouseArea, getWarehouseAreaById } =
    useWarehouseAreaService();
  const { getWarehouseRowsByAreaId, archiveWarehouseRow } =
    useWarehouseRowsService();

  const defaultValues = {
    warehouseId: warehouseId,
    warehouseAreaId: 0,
    name: "",
    code: "",
    storageModelTypeId: null,
    freeSpaceStorageCapacity: null,
  };
  const [areaData, setareaData] = useState<any>(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [warehouseRows, setWarehouseRows] = useState<any[]>([]);
  const [allotedSpaceForRows, setAllotedSpaceForRows] = useState<any>(0);

  const [isFormEditable, setIsFormEditable] = useState(true);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [areaData]);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setareaData({
      ...areaData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!areaData.name) {
      newErrors["name"] = t("Name is required");
    }
    if (!areaData.code) {
      newErrors["code"] = t("Code is required");
    }
    if (!areaData.storageModelTypeId) {
      newErrors["storageModelTypeId"] = t("Storage Model is required");
    }
    if (!areaData.freeSpaceStorageCapacity) {
      newErrors["freeSpaceStorageCapacity"] = t("Storage Capacity is required");
    }
    // check if the remaining space is less than the capacity of the area
    // also include the space of the current area if it is in edit mode

    if (warehouseAreaId) {
      if (
        totalSpaceInWarehouse &&
        allotedSpaceForAreas &&
        totalSpaceInWarehouse -
          (allotedSpaceForAreas - parseFloat(previouslyAllotedSpace)) <
          areaData.freeSpaceStorageCapacity
      ) {
        newErrors["freeSpaceStorageCapacity"] = t(
          `Storage Capacity cannot be more than the remaining space(${
            totalSpaceInWarehouse -
            (allotedSpaceForAreas - parseFloat(previouslyAllotedSpace))
          }) in the warehouse`
        );
      }
    } else if (
      totalSpaceInWarehouse &&
      allotedSpaceForAreas &&
      totalSpaceInWarehouse - allotedSpaceForAreas <
        parseFloat(areaData.freeSpaceStorageCapacity)
    ) {
      newErrors["freeSpaceStorageCapacity"] = t(
        `Storage Capacity cannot be more than the remaining space(${
          totalSpaceInWarehouse - allotedSpaceForAreas
        }) in the warehouse`
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const onSave = () => {
    if (!isDataChanged) {
      handleClose();
      return;
    }
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);
      const operation = warehouseAreaId
        ? updateWarehouseArea(areaData)
        : addWarehouseArea(areaData);
      operation
        .then(() => {
          warehouseAreaId
            ? setInfo(t("Warehouse Area updated successfully"))
            : setInfo(t("Warehouse Area added successfully"));
          handleClose();
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };
  const onEdit = (data: any) => {
    navigate("/warehouserowdetails", {
      state: {
        warehouseRowId: data.id,
        warehouseAreaId: state.warehouseAreaId,
        totalSpaceInArea: areaData.freeSpaceStorageCapacity,
        allotedSpaceForRows: allotedSpaceForRows,
      },
    });
  };
  const toCreate = () => {
    navigate("/warehouserowdetails", {
      state: {
        warehouseAreaId: state.warehouseAreaId,
        totalSpaceInArea: areaData.freeSpaceStorageCapacity,
        allotedSpaceForRows: allotedSpaceForRows,
      },
    });
  };

  const onArchive = (data: any) => {
    setIsLoading(true);
    archiveWarehouseRow(data.warehouseRowId)
      .then(() => {
        setInfo(t("Warehouse Row archived successfully"));
        getData();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const [previouslyAllotedSpace, setPreviouslyAllotedSpace] = useState<any>(0);
  const getData = () => {
    if (warehouseAreaId) {
      setIsLoading(true);
      const p1 = getWarehouseAreaById(warehouseAreaId);
      const p2 = getWarehouseRowsByAreaId(warehouseAreaId);
      Promise.all([p1, p2])
        .then(([area, rows]) => {
          setPreviouslyAllotedSpace(area.freeSpaceStorageCapacity);
          setareaData(area);
          const updatedRows = rows.map((row: any) => {
            return {
              ...row,
              rowModelType: getRowModelType(row.rowModelTypeId),
            };
          });
          const totalallotedSpaceForRows = updatedRows.reduce(
            (acc: any, row: any) => acc + row.capacityInCbm,
            0
          );
          setAllotedSpaceForRows(totalallotedSpaceForRows);

          setWarehouseRows(updatedRows);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Name")}
              name="name"
              value={areaData?.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Code")}
              name="code"
              value={areaData?.code}
              onChange={handleInputChange}
              error={errors["code"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Storage Model")}
              name="storageModelTypeId"
              value={areaData?.storageModelTypeId}
              items={storageModelTypes}
              onChange={handleInputChange}
              error={errors["storageModelTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: areaData?.storageModelTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Storage Capacity")}
              name="freeSpaceStorageCapacity"
              value={areaData?.freeSpaceStorageCapacity}
              onChange={handleInputChange}
              error={errors["freeSpaceStorageCapacity"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <InputAdornment position="end">CBM</InputAdornment>
                ),
              }}
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      {warehouseAreaId && areaData?.storageModelTypeId == 1 && (
        <ItemBox>
          <BoxDX
            sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
          >
            <TypographyDX variant="h5" sx={{}}>
              {t("Rows")}
            </TypographyDX>
            <ButtonDX
              variant="contained"
              color="primary"
              onClick={toCreate}
              sx={{ maxWidth: 120 }}
            >
              {t("Add Row")}
            </ButtonDX>
          </BoxDX>
          <DataGridDX
            getRowId={(row: any) => row.warehouseRowId}
            rows={warehouseRows}
            columns={rowColumns}
            loading={isLoading}
            disableRowSelectionOnClick={true}
            checkboxSelection={false}
            onRowClick={onEdit}
          />
        </ItemBox>
      )}
    </BoxDX>
  );
};

export default WarehouseAreaDetails;
