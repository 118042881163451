import { useTranslation } from "react-i18next";

const TypeTranslator = () => {
  const { i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  // storage type
  const storageType: any = [];
  // arabic translation should be there
  storageType[1] = { id: 1, en_value: "Chilled", ar_value: "مبرد" };
  storageType[2] = { id: 2, en_value: "Dry", ar_value: "جاف " };
  storageType[3] = { id: 3, en_value: "Frozen", ar_value: "مجمد" };
  storageType[4] = { id: 4, en_value: "Ambient", ar_value: "المحيط" };

  const getStorageTypeValue = (value: number) => {
    const status = storageType[value];

    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const quotationStatuses: any = [];
  // 1 draft color is light blue
  // 2 pending color is orange
  // 3 processed color is green

  quotationStatuses[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  quotationStatuses[2] = {
    id: 2,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  quotationStatuses[3] = { id: 3, en_value: "Processed", ar_value: "معالج" };

  const getQuotationStatusValue = (value: number) => {
    const status = quotationStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const leadRequestStatuses: any = [];
  // 1 pending color is orange
  // 2 processed color is green

  leadRequestStatuses[1] = {
    id: 1,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  leadRequestStatuses[2] = { id: 2, en_value: "Processed", ar_value: "معالج" };

  const getLeadRequestStatusValue = (value: number) => {
    const status = leadRequestStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const leadRequestType: any = [];
  // via website
  // Manual
  leadRequestType[1] = {
    id: 1,
    en_value: "Via Website",
    ar_value: "عبر الموقع الالكتروني",
  };
  leadRequestType[2] = { id: 2, en_value: "Manual", ar_value: "يدوي" };

  const getLeadRequestTypeValue = (value: number) => {
    const status = leadRequestType[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const rentalPeriods: any = [];
  // 1 month, 3 months, 6 months, annual

  rentalPeriods[1] = { id: 1, en_value: "1 Month", ar_value: "شهر واحد" };
  rentalPeriods[2] = { id: 2, en_value: "3 Months", ar_value: "3 أشهر" };
  rentalPeriods[3] = { id: 3, en_value: "6 Months", ar_value: "6 أشهر" };
  rentalPeriods[4] = { id: 4, en_value: "Annual", ar_value: "سنوي" };

  const getRentalPeriodValue = (value: number) => {
    const status = rentalPeriods[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const inboundDeliveryType: any = [];
  //a. Customer Drop-off
  //  b. Units PIckup
  //  c. Supplier Drop-off

  inboundDeliveryType[1] = {
    id: 1,
    en_value: "Customer Drop-off",
    ar_value: "تسليم العميل",
  };
  inboundDeliveryType[2] = {
    id: 2,
    en_value: "Units Pickup",
    ar_value: "استلام الوحدات",
  };
  inboundDeliveryType[3] = {
    id: 3,
    en_value: "Supplier Drop-off",
    ar_value: "تسليم المورد",
  };

  const getInboundDeliveryTypeValue = (value: number) => {
    const status = inboundDeliveryType[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const packagingTypes: any = [];
  // 1. Box
  // 2. Pallet
  // 3. Carton
  packagingTypes[1] = { id: 1, en_value: "Box", ar_value: "صندوق" };
  packagingTypes[2] = { id: 2, en_value: "Pallet", ar_value: "منصة" };
  packagingTypes[3] = { id: 3, en_value: "Carton", ar_value: "كرتون" };

  const getPackagingTypeValue = (value: number) => {
    const status = packagingTypes[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const inboundRequestStatuses: any = [];

  inboundRequestStatuses[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  inboundRequestStatuses[2] = {
    id: 2,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  inboundRequestStatuses[3] = { id: 3, en_value: "Accepted", ar_value: "قبول" };
  inboundRequestStatuses[4] = { id: 4, en_value: "Declined", ar_value: "رفض" };
  inboundRequestStatuses[5] = { id: 5, en_value: "Modified", ar_value: "معدل" };
  inboundRequestStatuses[6] = {
    id: 6,
    en_value: "Modification Approved",
    ar_value: "تمت الموافقة على التعديل",
  };
  inboundRequestStatuses[7] = {
    id: 7,
    en_value: "Modification Rejected",
    ar_value: "تم رفض التعديل",
  };
  inboundRequestStatuses[8] = {
    id: 8,
    en_value: "Approved",
    ar_value: "موافق",
  };
  inboundRequestStatuses[9] = {
    id: 9,
    en_value: "Rejected",
    ar_value: "مرفوض",
  };

  const getInboundRequestStatusValue = (value: number) => {
    const status = inboundRequestStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const outboundRequestStatuses: any = []
  outboundRequestStatuses[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  outboundRequestStatuses[2] = { id: 2, en_value: "Pending", ar_value: "قيد الانتظار" };
  outboundRequestStatuses[3] = { id: 3, en_value: "Accepted", ar_value: "قبول" };
  outboundRequestStatuses[4] = { id: 4, en_value: "Declined", ar_value: "رفض" };
  outboundRequestStatuses[5] = { id: 5, en_value: "Modified", ar_value: "معدل" };
  outboundRequestStatuses[6] = { id: 6, en_value: "Modification Approved", ar_value: "تمت الموافقة على التعديل" };
  outboundRequestStatuses[7] = { id: 7, en_value: "Modification Rejected", ar_value: "تم رفض التعديل" };
  outboundRequestStatuses[8] = { id: 8, en_value: "Approved", ar_value: "موافق" };
  outboundRequestStatuses[9] = { id: 9, en_value: "Rejected", ar_value: "مرفوض" };
  const getOutboundRequestStatusValue = (value: number) => {
    const status = outboundRequestStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  }


  // outbound delivery type
  // 1. Customer Pickup
  // 2. Units Delivery
  // 3. Supplier Pickup
  const outboundDeliveryType: any = [];

  outboundDeliveryType[1] = { id: 1, en_value: "Customer Pickup", ar_value: "استلام العميل" };
  outboundDeliveryType[2] = { id: 2, en_value: "Units Delivery", ar_value: "تسليم الوحدات" };
  outboundDeliveryType[3] = { id: 3, en_value: "Supplier Pickup", ar_value: "استلام المورد" };

  const getOutboundDeliveryTypeValue = (value: number) => {
    const status = outboundDeliveryType[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  // warehousetypes Dry, Chilled, Freezed
  const warehouseTypes = [
    { value: 1, text: "Dry" },
    { value: 2, text: "Chilled" },
    { value: 3, text: "Freezed" },
  ];
  const getWarehouseType = (value: number) => {
    const warehouseType = warehouseTypes.find((x) => x.value == value);
    return warehouseType ? warehouseType.text : value;
  }
  const storageModelTypes = [
    { value: 1, text: "Rack" },
    { value: 2, text: "Free Space" },
  ];
  const getStorageModelType = (value: number) => {
    const storageModelType = storageModelTypes.find((x) => x.value == value);
    return storageModelType ? storageModelType.text : value;
  }



  const rowModelTypes = [
    { value: 1, text: "Drive-In" },
    { value: 2, text: "Drive-Thru" },
    { value: 3, text: "Selective" },
    { value: 4, text: "Very Narrow Isle (VNA)" },
    { value: 5, text: "Radio Shuffle" },
    { value: 6, text: "Mobile Rack" },
    { value: 7, text: "Long Span" },
    { value: 8, text: "Mobile Shelf" },
  ];

  const getRowModelType = (value: number) => {
    const rowModelType = rowModelTypes.find((x) => x.value == value);
    return rowModelType ? rowModelType.text : value;
  }

  return {
    getStorageTypeValue,
    getQuotationStatusValue,
    quotationStatuses,
    getLeadRequestStatusValue,
    leadRequestStatuses,
    getLeadRequestTypeValue,
    getRentalPeriodValue,
    getInboundDeliveryTypeValue,
    getPackagingTypeValue,
    getInboundRequestStatusValue,
    inboundRequestStatuses,
    getOutboundRequestStatusValue,
    outboundRequestStatuses,
    getOutboundDeliveryTypeValue,
    warehouseTypes,
    getWarehouseType,
    storageModelTypes,
    getStorageModelType,
    rowModelTypes,
    getRowModelType,

  };
};

export default TypeTranslator;
