import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import BoxDX from "../../layout/boxdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import useCityService from "../../../shared/services/cityservice";
import ItemBox from "../../units_components/itembox";
import { InputAdornment } from "@mui/material";
import CheckBoxDX from "../../controls/checkboxdx";
import useWarehouseService from "../../../shared/services/warehouseservice";
import TypeTranslator from "../../../shared/typetranslator";
import useWarehouseAreaService from "../../../shared/services/warehouseareaservice";
import TypographyDX from "../../layout/typographydx";
import ButtonDX from "../../controls/buttondx";
import DataGridDX from "../../layout/datagriddx";
import { Edit, Archive } from "@mui/icons-material";
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import SubscriptionDetailsModal from "./subscriptiondetailsmodal";
import useCustomerService from "../../../shared/services/customerservice";
import { useAuthContext } from "../../../context/authcontext";
import moment from "moment";
import { DATE_FORMAT } from "../../../shared/globals";
import useSubscriptionPackageService from "../../../shared/services/subscriptionpackageservice";
import useQuotationService from "../../../shared/services/quotationservice";

const CustomerDetails = (props: any) => {
  const { state } = useLocation();
  const customerId = state?.customerId;
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { addCustomer, getCustomerById } = useCustomerService();
  const { userData } = useAuthContext();
  const { getWarehouses } = useWarehouseService();
  const { getSubscriptionPackages } = useSubscriptionPackageService();
  const { getQuotations } = useQuotationService();
  const defaultValues = {
    userId: userData.userId,
    fullname: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    // subscriptions: [
    //   // {
    //   //   customerId: 0,
    //   //   warehouseId: 1,
    //   //   quotationId: 15,
    //   //   subscriptionPackageId: 1,
    //   //   subscribedCbm: 100,
    //   //   startDate: "2024-05-14T15:45:34.791Z",
    //   // },
    // ],
  };

  const navigate = useNavigate();
  const { setInfo, setError } = useNotificationContext();

  const [customerData, setCustomerData] = useState<any>(defaultValues);
  const [customerSubscriptions, setCustomerSubscriptions] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [warehouses, setWarehouses] = useState<any>([]);
  const [warehouseDropdownData, setWarehouseDropdownData] = useState<any>([]);

  const [packages, setPackages] = useState<any>([]);
  const [packageDropdownData, setPackageDropdownData] = useState<any>([]);
  const [quotationDropdownData, setQuotationDropdownData] = useState<any>([]);

  const [subscriptionId, setSubscriptionId] = useState(null);

  const getDropDownData = () => {
    const p1 = getWarehouses();
    const p2 = getSubscriptionPackages();
    const p3 = getQuotations();
    Promise.all([p1, p2, p3]).then(([warehouses, packages, quotations]) => {
      setWarehouses(warehouses);
      setWarehouseDropdownData(
        warehouses.map((warehouse: any) => ({
          text: warehouse.name,
          value: warehouse.warehouseId,
        }))
      );
      setPackages(packages);
      setPackageDropdownData(
        packages.map((pkg: any) => ({
          text: pkg.name,
          value: pkg.subscriptionPackageId,
        }))
      );
      setQuotationDropdownData(
        quotations.map((quot: any) => ({
          text: quot.quotationNumber,
          value: quot.quotationId,
        }))
      );
    });
  };
  useEffect(() => {
    getDropDownData();
  }, []);

  // Refs for input fields
  const pocNameRef = useRef<HTMLInputElement>(null);
  const pocPhoneRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setCustomerData({
      ...customerData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!customerData.fullname) {
      newErrors["fullname"] = t("Name is required");
    }
    if (!customerData.phoneNumber) {
      newErrors["phoneNumber"] = t("Phone number is required");
    }
    if (!customerData.email) {
      newErrors["email"] = t("Email is required");
    }
    if (!customerData.companyName) {
      newErrors["companyName"] = t("Company name is required");
    }
    console.log(customerSubscriptions);
    if (customerSubscriptions.length === 0) {
      setError(t("At least one subscription is required"));
      newErrors["subscriptions"] = t("At least one subscription is required");
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: any) => {
    // temporary
    if (customerId) {
      handleClose();
      return;
    }
    if (validateForm()) {
      if (!isDataChanged) {
        handleClose();
        return;
      }
      setIsSaving(true);
      setIsFormEditable(false);
      // const operation = customerId
      //   ? updateWarehouse(customerData)
      //   : addWarehouse(customerData);
      // operation
      // remove id from subscriptions before saving
      const customerSubscriptionsToSave = customerSubscriptions.map(
        (sub: any) => {
          const { id, ...rest } = sub;
          return rest;
        }
      );
      addCustomer({ ...customerData, subscriptions: customerSubscriptionsToSave })
        .then(() => {
          customerId
            ? setInfo(t("Customer updated successfully"))
            : setInfo(t("Customer added successfully"));
          handleClose();
        })
        .catch((error: any) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };

  const getData = () => {
    if (customerId) {
      setIsLoading(true);
      getCustomerById(customerId)
        .then((response) => {
          const { subscriptions, ...rest } = response;
          setCustomerSubscriptions(
            subscriptions.map((sub: any) => ({
              ...sub,
              // adding a random id to each subscription for the grid  and to help in edit in deleting
              id: Math.floor(Math.random() * 1000),
            }))
          );
          setCustomerData(rest);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [customerData, customerSubscriptions]);

  const subscriptionColumns: GridColDef[] = [
    {
      field: "subscriptionPackageId",
      headerName: `${t("Package Name")}`,
      flex: 1,
      valueFormatter: (params) => {
        return packages.find(
          (pkg: any) => pkg.subscriptionPackageId === params.value
        )?.name;
      },
    },
    {
      field: "warehouseId",
      headerName: `${t("Warehouse")}`,
      flex: 1,
      valueFormatter: (params) => {
        return warehouses.find((w: any) => w.warehouseId === params.value)
          ?.name;
      },
    },
    {
      field: "subscribedCbm",
      headerName: `${t("Subscribed CBM")}`,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: `${t("Start Date")}`,
      flex: 1,
      valueFormatter: (params) => moment(params.value).format(DATE_FORMAT),
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const toCreate = () => {
    setShowSubscriptionModal(true);
  };
  const onEdit = (data: any) => {
    if (data.row.id) {
      setSubscriptionId(data.row.id);
    }

    setShowSubscriptionModal(true);
  };
  const onArchive = (row: any) => {
    if (row.id) {
      setCustomerSubscriptions((prev: any) =>
        prev.filter((sub: any) => sub.id !== row.id)
      );
    }
  };
  const handleCLoseSubscriptionModal = () => {
    setShowSubscriptionModal(false);
    setSubscriptionId(null);
  };
  return (
    <BoxDX sx={{ width: "100%" }}>
      {showSubscriptionModal && (
        <SubscriptionDetailsModal
          handleClose={handleCLoseSubscriptionModal}
          setCustomerSubscriptions={setCustomerSubscriptions}
          customerSubscriptions={customerSubscriptions}
          packages={packages}
          warehouseDropdownData={warehouseDropdownData}
          packageDropdownData={packageDropdownData}
          quotationDropdownData={quotationDropdownData}
          subscriptionId={subscriptionId}
        />
      )}
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Name")}
              name="fullname"
              value={customerData.fullname}
              onChange={handleInputChange}
              error={errors["fullname"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Phone Number")}
              name="phoneNumber"
              value={customerData.phoneNumber}
              onChange={handleInputChange}
              error={errors["phoneNumber"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Email")}
              name="email"
              value={customerData.email}
              onChange={handleInputChange}
              error={errors["email"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Company Name")}
              name="companyName"
              value={customerData.companyName}
              onChange={handleInputChange}
              error={errors["companyName"]}
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      <ItemBox>
        <BoxDX sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <TypographyDX variant="h5" sx={{}}>
            {t("Subscriptions")}
          </TypographyDX>
          <ButtonDX
            variant="contained"
            color="primary"
            onClick={toCreate}
            disabled={!isFormEditable}
          >
            {t("Add Subscription")}
          </ButtonDX>
        </BoxDX>

        <DataGridDX
          getRowId={(row: any) => row.id}
          rows={customerSubscriptions}
          columns={subscriptionColumns}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          checkboxSelection={false}
          onRowClick={onEdit}
        />
      </ItemBox>
    </BoxDX>
  );
};

export default CustomerDetails;
