import { useEffect, useState } from "react";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import AddEditEmbeddedModal from "../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../context/notificationcontext";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authcontext";
import { useTranslation } from "react-i18next";
import useUserService from "../../shared/services/userservices";
import { RegularExpressions, validatePassword } from "../../shared/globals";

const UserDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const userId = state?.userId;
  const handleClose = () => {
    navigate(-1);
  };

  const authContext = useAuthContext();
  const { t } = useTranslation();

  const { setError, setInfo } = useNotificationContext();
  const { addUser, updateUser, getUserById } = useUserService();
  const defaultValues = {
    userType: authContext.userData.userType,
    customerId: authContext.userData.customerId,
    email: "",
    password: "",
    fullName: "",
    mobile: "",
    jobTitle: "",
    isActive: true,
    roleIds: [],
    // for put request
    oldPassword: "",
  };

  const [userData, setUserData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    const p1 = userId ? getUserById(userId) : null;
    Promise.all([p1])
      .then(([user]) => {
        if (userId) {
          const { roles, ...rest } = user;
          setUserData({
            ...rest,
            password: "",
            roleIds: [],
          });
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (userData.fullName.length === 0)
      newErrors["fullName"] = t("Name is required");
    if (userData.password !== "") {
      const error = validatePassword(userData.password);
      if (error !== "") newErrors["password"] = t(error);
    } else if (!userId && userData.password.length === 0)
      newErrors["password"] = t("Password is required");
    if (userData.email.length === 0)
      newErrors["email"] = t("Email is required");
    if (!RegularExpressions.emailAddress.test(userData.email))
      newErrors["email"] = t("Invalid email address");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const { roleIds } = userData;
    const roles = roleIds.map((e: any) => e.value);
    const operation = userId
      ? updateUser(userId, { ...userData, roleIds: roles })
      : addUser({ ...userData, roleIds: roles });
    operation
      .then(() => {
        setInfo(
          t(userId ? "User updated successfully" : "User created successfully")
        );
        handleClose();
      })
      .catch((err) => {
        if (err == "USER_ALREADY_EXISTS") {
          setError(
            t(`Email`) + " " + userData.email + " " + t("is already taken")
          );
        } else if (err == "OLD_PASSWORD_DOESNOT_MATCH") {
          setError(t("Old password does not match"));
        } else {
          setError(err);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setUserData({
      ...userData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("User Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      <GridDX
        container
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 13px 1px #00000008",
          ml: "10px",
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Full Name")}
            name="fullName"
            value={userData.fullName}
            onChange={handleInputChange}
            error={errors["fullName"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Email")}
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            error={errors["email"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Mobile")}
            name="mobile"
            value={userData.mobile}
            onChange={handleInputChange}
            error={errors["mobile"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        {userId && (
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              label={t("Old Password")}
              name="oldPassword"
              value={userData.oldPassword}
              onChange={handleInputChange}
              error={errors["oldPassword"]}
            />
          </GridDX>
        )}

        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("New Password")}
            name="password"
            value={userData.password}
            onChange={handleInputChange}
            error={errors["password"]}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Job Title")}
            name="jobTitle"
            value={userData.jobTitle}
            onChange={handleInputChange}
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default UserDetails;
