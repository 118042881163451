import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseLevelService = () => {
  const secureAPI = useSecureAPI();

  
  const addWarehouseLevel = async (data: any) => {
    const response = await secureAPI.post("WarehouseLevel", data);
    return response.data;
  };
  const getWarehouseLevelsByBayId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseLevel/forbay/${id}`);
    return response.data;
  }
  const getWarehouseLevelById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseLevel/${id}`);
    return response.data;
  }

  const updateWarehouseLevel = async (data: any) => {
    const response = await secureAPI.put(`WarehouseLevel`, data);
    return response.data;
  }
  const archiveWarehouseLevel = async (id: number) => {
    const response = await secureAPI.put(`WarehouseLevel/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseLevel,
    getWarehouseLevelsByBayId,
    getWarehouseLevelById,
    updateWarehouseLevel,
    archiveWarehouseLevel
  };
};

export default useWarehouseLevelService;
