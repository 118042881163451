import { Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import BoxDX from "./boxdx";

const GaugeBoxDX = (props: any) => {
  const { title, value } = props;
  return (
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        p: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <BoxDX display="flex" flexDirection="row" width="100%">
        <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1 }}>
          {title}
        </Typography>
      </BoxDX>
      <BoxDX width="100%">
        <Gauge
          height={250}
          value={325}
          valueMax={540}
          text={({ value, valueMax }) => `${value} / ${valueMax} \n (cbm)`}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 20,
            },
          })}
        />
      </BoxDX>
    </BoxDX>
  );
};

export default GaugeBoxDX;
