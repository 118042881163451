import { useTranslation } from "react-i18next";
import GridDX from "../../../layout/griddx";
import Loading from "../../../layout/loading";
import PageTitle from "../../../layout/pagetitle";
import StaticDataGridDX from "../../../layout/staticdatagriddx";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import useReportService from "../../../../shared/services/reportservice";
import { useAuthContext } from "../../../../context/authcontext";
import { useNotificationContext } from "../../../../context/notificationcontext";
import { usePDF } from "react-to-pdf";
import ButtonDX from "../../../controls/buttondx";
import { downloadPDF } from "../../../../shared/globals";
import TypographyDX from "../../../layout/typographydx";
import SelectListDX from "../../../controls/selectlistdx";
import BoxDX from "../../../layout/boxdx";
import useUserService from "../../../../shared/services/userservices";

const SKUReport = () => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { setError } = useNotificationContext();
  const { getActiveUsers } = useUserService();
  const { getReportsForCustomer } = useReportService();

  const [rows, setRows] = useState<any>(null);
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState<any>(null);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const arr: any[] = [
    {
      warehouseName: "A",
      warehouseAddress: "Location A",
      skuCode: 11,
      skuName: "Product X",
      quantity: 44,
    },
    {
      warehouseName: "B",
      warehouseAddress: "Location B",
      skuCode: 12,
      skuName: "Product Y",
      quantity: 12,
    },
    {
      warehouseName: "A",
      warehouseAddress: "Location A",
      skuCode: 13,
      skuName: "Product Z",
      quantity: 8,
    },
    {
      warehouseName: "B",
      warehouseAddress: "Location B",
      skuCode: 14,
      skuName: "Product P",
      quantity: 23,
    },
    {
      warehouseName: "A",
      warehouseAddress: "Location A",
      skuCode: 15,
      skuName: "Product Q",
      quantity: 6,
    },
    {
      warehouseName: "C",
      warehouseAddress: "Location C",
      skuCode: 16,
      skuName: "Product R",
      quantity: 85,
    },
    {
      warehouseName: "C",
      warehouseAddress: "Location C",
      skuCode: 17,
      skuName: "Product S",
      quantity: 31,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "skuCode",
      headerName: t("SKU Code"),
    },
    {
      field: "skuName",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "quantity",
      headerName: t("Quantity"),
    },
  ];

  useEffect(() => {
    userData.userType === "S" && getCustomers();
  }, []);

  useEffect(() => {
    getData();
  }, [customerId]);

  const getData = async () => {
    const id = userData.userType === "C" ? userData.customerId : customerId;
    if (id) {
      setIsLoading(true);
      getReportsForCustomer(id)
        .then((res) => {
          let grouped = arr.reduce((result: any, currentValue: any) => {
            (result[currentValue["warehouseName"]] =
              result[currentValue["warehouseName"]] || []).push(currentValue);
            return result;
          }, {});

          setRows(grouped);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const getCustomers = async () => {
    getActiveUsers()
      .then((res) => {
        const users = res.filter((user: any) => user.userType === "C");
        setCustomers(
          users.map((user: any) => {
            return { text: user.fullName, value: user.customerId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setCustomerId(value);
  };

  const { toPDF, targetRef } = usePDF({
    filename: t("SKU Report"),
    page: {
      margin: 10, // Set the margin in millimeters
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });

  return (
    <div ref={targetRef}>
      {(isPdfGenerating || isLoading) && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
        <GridDX item xs={6} sx={{ marginBottom: 5 }}>
          <PageTitle />
        </GridDX>
        <GridDX
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {!isPdfGenerating && (
            <ButtonDX
              disabled={!rows}
              onClick={() => downloadPDF({ toPDF, setIsPdfGenerating })}
            >
              {t("Download Report")}
            </ButtonDX>
          )}
        </GridDX>
        {userData.userType === "S" && (
          <GridDX
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <BoxDX width={350}>
              <SelectListDX
                label={t("Select Customer")}
                name="customerId"
                items={customers}
                value={customerId}
                onChange={handleInputChange}
              />
            </BoxDX>
          </GridDX>
        )}
        {rows &&
          !isLoading &&
          Object.values(rows).map((row: any) => (
            <>
              <GridDX item xs={3}>
                <TypographyDX fontWeight={"bold"}>
                  {t("Warehouse")}
                </TypographyDX>
                <TypographyDX>{`: ${row[0].warehouseName}`}</TypographyDX>
              </GridDX>
              <GridDX item xs={9}>
                <TypographyDX fontWeight={"bold"}>{t("Address")}</TypographyDX>
                <TypographyDX>{`: ${row[0].warehouseAddress}`}</TypographyDX>
              </GridDX>
              <GridDX item xs={12}>
                <StaticDataGridDX
                  rows={row}
                  columns={columns}
                  getRowId={(row: any) => row.skuCode}
                  isLoading={isLoading}
                />
              </GridDX>
            </>
          ))}
      </GridDX>
    </div>
  );
};

export default SKUReport;
