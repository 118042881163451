import { useTranslation } from "react-i18next";
import GridDX from "../../layout/griddx";
import PageTitle from "../../layout/pagetitle";
import CountBoxDX from "../../layout/countboxdx";
import {
  ContentPasteOutlined,
  PendingActionsOutlined,
} from "@mui/icons-material";
import GridBoxDX from "../../layout/gridboxdx";
import { GridColDef } from "@mui/x-data-grid";
import useDashboardService from "../../../shared/services/dashboardservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useEffect, useState } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../../shared/globals";

const CustomerDashboard = () => {
  const { t } = useTranslation();
  const { getOrderCount, getExpiringContracts } = useDashboardService();
  const { setError } = useNotificationContext();

  const [orderCount, setOrderCount] = useState<any>({
    inbounds: 0,
    outbounds: 0,
    inboundsPending: 0,
    outboundsPending: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [expiringContracts, setExpiringContracts] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Package Name"),
      flex: 1,
      valueGetter(params) {
        return params.row.subscriptionPackage.name;
      },
    },
    {
      field: "endDate",
      headerName: t("Expiration Date"),
      width: 300,
      valueFormatter(params) {
        return moment(params.value).format(DATE_FORMAT);
      },
    },
  ];

  const stockColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "stock",
      headerName: t("Remaining Stock"),
      width: 150,
    },
  ];

  const expiryColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "days",
      headerName: t("Days till Expiration"),
      width: 150,
    },
    {
      field: "date",
      headerName: t("Expiration Date"),
      width: 150,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const p1 = getOrderCount();
    const p2 = getExpiringContracts();

    setIsLoading(true);
    Promise.all([p1, p2])
      .then(([orders, contracts]) => {
        setOrderCount(orders);
        setExpiringContracts(contracts);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={6} sx={{ marginBottom: 5 }}>
        <PageTitle />
      </GridDX>
      <GridDX
        item
        xs={6}
        sx={{
          marginBottom: 5,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      ></GridDX>
      <GridDX container xs={3} rowSpacing={2} mt={1} pl={1}>
        <GridDX item xs={12} mb={3}>
          <CountBoxDX
            title={t("Orders")}
            icon={<ContentPasteOutlined color="primary" fontSize="large" />}
            count1={orderCount.inbounds}
            label1={t("Inbounds")}
            count2={orderCount.outbounds}
            label2={t("Outbounds")}
            loading={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Pending Approval")}
            icon={<PendingActionsOutlined color="primary" fontSize="large" />}
            count1={orderCount.inboundsPending}
            label1={t("Inbounds")}
            count2={orderCount.outboundsPending}
            label2={t("Outbounds")}
            loading={isLoading}
          />
        </GridDX>
      </GridDX>
      <GridDX item xs={9} mt={1}>
        <GridBoxDX
          title={t("Subscriptions Expiring in 30 Days")}
          rows={expiringContracts}
          columns={columns}
          getRowId={(row: any) => row.subscriptionId}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={6}>
        <GridBoxDX
          title={t("Low Stock Items")}
          rows={[]}
          columns={stockColumns}
          getRowId={(row: any) => row.id}
        />
      </GridDX>
      <GridDX item xs={6}>
        <GridBoxDX
          title={t("Near Expiry Items")}
          rows={[]}
          columns={expiryColumns}
          getRowId={(row: any) => row.id}
        />
      </GridDX>
    </GridDX>
  );
};

export default CustomerDashboard;
