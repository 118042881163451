import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../components/business/listpagedx";
import SkuForm from "../components/units_forms/sku/skuform";
import useSKUService from "../shared/services/skuservice";
import { useNotificationContext } from "../context/notificationcontext";
import { useAuthContext } from "../context/authcontext";
import SKUBulkForm from "../components/units_forms/sku/skubulkform";

const SKU = () => {
  const { t, i18n } = useTranslation();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { userData } = useAuthContext();
  const { setInfo, setError } = useNotificationContext();
  const { getSKUsForCustomer, deleteSKU } = useSKUService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [importForm, setImportForm] = useState(false);
  const [skuId, setSkuId] = useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: `${t("SKU Code")}`,
    },
    {
      field: "name",
      headerName: `${t("Name")}`,
      minWidth: 200,
    },
    {
      field: "specification",
      headerName: `${t("Specification")}`,
      flex: 1,
      //  just show first 50 characters then add "..." on hover

      renderCell: (params) => {
        const { value } = params;
        return (
          <div title={value}>
            {value.length > 100 ? value.substr(0, 100) + "..." : value}
          </div>
        );
      },
    },
  ];

  const getData = () => {
    if (userData.userType === "S") {
      setRows([]);
      setDataFromApi([]);
      setShowAddButton(false);
    } else {
      setIsLoading(true);
      getSKUsForCustomer(userData?.customerId)
        .then((data) => {
          setDataFromApi(data);
          setRows(data);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onEdit = (data: any) => {
    setSkuId(data.skuId);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toImport = () => {
    setImportForm(true);
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteSKU(id)
      .then(() => {
        setInfo(t("SKU deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setImportForm(false);
    setSkuId(null);
    setShowAddButton(userData.userType === "S" ? false : true);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.code.toLowerCase().includes(value.toLowerCase()) ||
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.specification.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  useEffect(() => {
    setDataFromApi(rows);
    getData();
    setAddRecordfn(() => toCreate);

    return () => {
      setShowAddButton(true);
      setShowTitle(true);
      setAddRecordfn(null);
    };
  }, [i18n.language]);

  return (
    <>
      {showForm && <SkuForm id={skuId} handleClose={onClose} />}
      {importForm && <SKUBulkForm open={importForm} handleClose={onClose} />}
      {!showForm && (
        <ListPageDX
          listTitle={t("SKU")}
          name={t("SKU")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.skuId}
          isLoading={isLoading}
          onEdit={onEdit}
          onDelete={onDelete}
          editLabel={t("Preview")}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={false}
          showSelect={false}
          showImportButton
          onImport={toImport}
          importTemplateLink={"../assets/templates/UnitsSKUTemplate.csv"}
        />
      )}
    </>
  );
};

export default SKU;
