import { Outlet } from "react-router-dom";
import GridDX from "../components/layout/griddx";
import PageTitle from "../components/layout/pagetitle";
import { AddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const UnitsListPageTemplate = () => {
  const { t } = useTranslation();
  const [showTitle, setShowTitle] = useState<boolean>(true);
  const [showAddButton, setShowAddButton] = useState<boolean>(true);
  const [addRecordfn, setAddRecordfn] = useState<any>(null);
  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={12} sx={{ alignItems: "center" }}>
        {showTitle && <PageTitle />}
        {showAddButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => addRecordfn && addRecordfn()}
          >
            <AddOutlined /> {t("Add Record")}
          </Button>
        )}
      </GridDX>

      <GridDX item xs={12}>
        <Outlet context={{ setShowTitle, setShowAddButton, setAddRecordfn }} />
      </GridDX>
    </GridDX>
  );
};

export default UnitsListPageTemplate;
