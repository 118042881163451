import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PageTitle = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const routeMap = new Map<string, string>([
    ["dashboard", t("Dashboard")],
    ["reports", t("Reports")],
    ["settings", t("Settings")],
    ["usergroup", t("User Groups")],
    ["users", t("Users")],
    ["userprofile", t("Profile")],
    ["leads", t("Leads")],
    ["quotations", t("Quotations")],
    ["outboundrequests", t("Outbound Requests")],
    ["inboundrequests", t("Inbound Requests")],
    ["movementrequests", t("Movement Requests")],
    ["warehouses", t("Warehouses")],
    ["warehousedetails", t("Warehouse Details")],
    ["warehouseareadetails", t("Warehouse Area")],
    ["warehouserowdetails", t("Warehouse Row")],
    ["warehousebaydetails", t("Warehouse Bay")],
    ["packages", t("Packages")],
    ["sku", t("SKU")],
    ["customeraddress", t("Customer Address")],
    ["skureport", t("SKU Report")],
    ["customers", t("Customers")],
    ["customerdetails", t("Customer Details")],
  ]);

  const [pageHeading, setPageHeading] = useState("Dashboard");

  useEffect(() => {
    let pathName = location.pathname.substring(1);
    const idPath = pathName.indexOf("/");
    if (idPath != -1) {
      pathName = pathName.substring(0, idPath);
    }

    updatePageHeading(routeMap.get(pathName));
  }, [location.pathname, i18n.language]);

  const updatePageHeading = async (newPageHeading: string | undefined) => {
    setPageHeading(newPageHeading ?? "Page Title");
  };

  return (
    <Typography
      variant="h4"
      component="div"
      sx={{ flexGrow: 1, fontWeight: "bold", py: 2 }}
    >
      {pageHeading}
    </Typography>
  );
};

export default PageTitle;
