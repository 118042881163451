import { apiCall } from "../api/api";
import useSecureAPI from "../hooks/usesecureapi";

const useCustomerService = () => {
  const secureAPI = useSecureAPI();

  const getCustomers = async () => {
    const response = await secureAPI.get("Customer");
    return response.data;
  };

  const getCustomerById = async (id: number) => {
    const response = await secureAPI.get(`Customer/${id}`);
    return response.data;
  };

  const addCustomer = async (data: any) => {
    const response = await secureAPI.post("Customer", data);
    return response.data;
  };

  const updateCustomer = async (data: any) => {
    const response = await secureAPI.put(`Customer`, data);
    return response.data;
  };

  const deleteCustomer = async (id: number) => {
    const response = await secureAPI.delete(`Customer/${id}`);
    return response.data;
  };
  const archiveCustomer = async (id: number) => {
    const response = await secureAPI.put(`Customer/archive/${id}`);
    return response.data;
  }
  return {
    getCustomers,
    getCustomerById,
    addCustomer,
    updateCustomer,
    deleteCustomer,
    archiveCustomer
  };
};

export default useCustomerService;
