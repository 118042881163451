import { Typography } from "@mui/material";
import BoxDX from "../layout/boxdx";
import ProgressBarDX from "../layout/progressbardx";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { useState } from "react";

const UtilizationBox = (props: any) => {
  const { t } = useTranslation();

  const items = [
    {
      title: "Area 01",
      value: 38,
      items: [
        { title: "Row 01", value: 25, items: [] },
        {
          title: "Row 02",
          value: 68,
          items: [
            { title: "Bay 01", value: 15, items: [] },
            { title: "Bay 02", value: 48, items: [] },
          ],
        },
      ],
    },
    {
      title: "Area 02",
      value: 88,
      items: [
        { title: "Row 01", value: 34, items: [] },
        { title: "Row 02", value: 46, items: [] },
      ],
    },
    {
      title: "Area 03",
      value: 75,
      items: [
        { title: "Row 01", value: 5, items: [] },
        {
          title: "Row 02",
          value: 57,
          items: [
            { title: "Bay 01", value: 18, items: [] },
            { title: "Bay 02", value: 77, items: [] },
          ],
        },
      ],
    },
  ];

  return (
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        p: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <Typography fontSize={24} fontWeight="bold" sx={{ m: 1 }}>
        {t("List of Areas")}
      </Typography>
      <SectionsList items={items} />
    </BoxDX>
  );
};

const SectionsList = (props: any) => {
  const { items } = props;

  return items.map((item: any) => <ListItem item={item} />);
};

const ListItem = (props: any) => {
  const { item } = props;
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <BoxDX
        display="flex"
        flexDirection="row"
        sx={{
          width: "100%",
          height: 40,
          px: 2,
          alignItems: "center",
          borderRadius: 5,
          backgroundColor: open ? "#F0E5FF" : "transparent",
        }}
      >
        <Typography fontWeight="bold" sx={{ mx: 1, width: "100%" }}>
          {item.title}
        </Typography>
        <BoxDX
          display="flex"
          flexDirection="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Typography textAlign={"end"} sx={{ mx: 1, width: 200 }}>{`${t(
            "Used"
          )} 65/80 (cbm)`}</Typography>
          <BoxDX display="flex">
            <ProgressBarDX color="#6800F4" value={item.value} width={450} />
          </BoxDX>
          <Typography
            fontWeight="bold"
            sx={{
              ml: i18n.language === "ar" && item.items.length > 0 ? 1 : 6,
              mr: i18n.language === "en" && item.items.length > 0 ? 1 : 6,
              width: 60,
            }}
          >
            {`${item.value}%`}
          </Typography>
          {item.items.length > 0 && (
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </BoxDX>
      </BoxDX>
      <Collapse in={open} sx={{ p: 1 }}>
        <SectionsList items={item.items} />
      </Collapse>
    </>
  );
};

export default UtilizationBox;
