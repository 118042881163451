import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { useNotificationContext } from "../../context/notificationcontext";
import ListPageDX from "../../components/business/listpagedx";
import useWarehouseService from "../../shared/services/warehouseservice";
import TypeTranslator from "../../shared/typetranslator";

const WareHouse = () => {
  const { t, i18n } = useTranslation();
  const {  setAddRecordfn } =
    useOutletContext() as any;
  const { getWarehouses, archiveWarehouse } = useWarehouseService();
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouseType } = TypeTranslator();

  const navigate = useNavigate();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "streetAddress",
      headerName: `${t("Address")}`,
      flex: 1,
    },
    {
      field: "cityName",
      headerName: `${t("City")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "warehouseType",
      headerName: `${t("Warehouse Type")}`,
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "totalSpaceInCbm",
      headerName: `${t("Total Space (CBM)")}`,
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
  ];

  useEffect(() => {
    getData();
    setAddRecordfn(() => toCreate);
  }, [i18n.language]);

  const getData = () => {
    setIsLoading(false);
    getWarehouses()
      .then((data) => {
        const rows = data.map((row: any) => {
          return {
            ...row,
            warehouseType: getWarehouseType(row.warehouseTypeId),
          };
        });

        setDataFromApi(rows);
        setRows(rows);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onEdit = (data: any) => {
    navigate("/warehousedetails", {
      state: {
        warehouseId: data.warehouseId,
      },
    });
  };
  const toCreate = () => {
    navigate("/warehousedetails", {
      state: {},
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveWarehouse(id)
      .then(() => {
        setInfo(t("Record has been archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.description.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  return (
    <ListPageDX
      listTitle={t("Warehouses")}
      name={t("Warehouses")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.warehouseId}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete}
      deleteLabel={t("Archive")}
      editLabel={t("Preview")}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={false}
      showSelect={false}
    />
  );
};

export default WareHouse;
