import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { t } from "i18next";
import moment from "moment";
import {
  downloadPDF,
  DATE_FORMAT,
  numberWithCommas,
} from "../../../../shared/globals";
import ButtonDX from "../../../controls/buttondx";
import BoxDX from "../../../layout/boxdx";
import TypographyDX from "../../../layout/typographydx";

import { usePDF } from "react-to-pdf";

import customerLogo from "../../../../assets/customer_logo.png";
import TypeTranslator from "../../../../shared/typetranslator";
import LoadingButtonDX from "../../../controls/loadingbuttondx";

const QuotationInvoiceReport = (props: any) => {
  const { open, onClose, quotationData } = props;
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const { getQuotationStatusValue } = TypeTranslator();
  const { toPDF, targetRef } = usePDF({
    filename: t(`Quotation - ${quotationData?.quotationNumber}`),
    page: {
      margin: 10, // Set the margin in millimeters
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });

  const handlePrintQuotation = async () => {
    downloadPDF({ toPDF, setIsPdfGenerating, onClose });
  };

  return (
    quotationData &&
    open && (
      <Dialog
        open={open}
        onClose={!isPdfGenerating && onClose}
        maxWidth={props.maxWidth ?? "xl"}
        disableEscapeKeyDown
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Quotation Invoice
        </DialogTitle>
        <DialogActions
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <ButtonDX
            disabled={isPdfGenerating}
            variant="outlined"
            onClick={onClose}
            sx={{ mb: { xs: 2, sm: "auto" } }}
          >
            {props.cancelLabel ?? t("Cancel")}
          </ButtonDX>
          <LoadingButtonDX
            color="primary"
            onClick={handlePrintQuotation}
            loading={isPdfGenerating}
            sx={{ maxWidth: 120 }}
          >
            {t("Print")}
          </LoadingButtonDX>
        </DialogActions>
        <DialogContent>
          <div ref={targetRef} style={{ width: "1024px", padding: "10px" }}>
            <TypographyDX
              variant="h4"
              fontWeight="bold"
              // fontSize={"100px"}
              sx={{}}
            >
              QUOTATION
            </TypographyDX>
            <TypographyDX variant="body1" sx={{ fontFamily: "Almarai" }}>
              مؤسسة جودة الودائع للنقل والتخزين
            </TypographyDX>
            <BoxDX sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                width={180}
                style={{ objectFit: "cover" }}
                src={customerLogo}
                alt="Customer Logo"
              />
            </BoxDX>
            <Divider sx={{ borderBottomWidth: 2 }} />
            <BoxDX
              sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
            >
              <BoxDX>
                <TypographyDX variant="h6">Contact</TypographyDX>
                <TypographyDX variant="body1" sx={{ fontFamily: "Almarai" }}>
                  شركة المنتج الخليجي المحدودة للتجارة - شركة المنتج الخليجي
                  المحدودة
                </TypographyDX>
                <TypographyDX variant="body1" sx={{ fontFamily: "Almarai" }}>
                  للتجارة
                </TypographyDX>
              </BoxDX>

              <BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{
                      justifyContent: "flex-end",
                      width: 150,
                    }}
                  >
                    Status:
                  </TypographyDX>
                  <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                    {getQuotationStatusValue(quotationData.status)}
                  </TypographyDX>
                </BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ justifyContent: "flex-end", width: 150 }}
                  >
                    Invoice No:
                  </TypographyDX>
                  <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                    {quotationData.quotationNumber}
                  </TypographyDX>
                </BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ justifyContent: "flex-end", width: 150 }}
                  >
                    Invoice Date:
                  </TypographyDX>
                  <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                    {moment(quotationData.quotationDate).format(DATE_FORMAT)}
                  </TypographyDX>
                </BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ justifyContent: "flex-end", width: 150 }}
                  >
                    Due On:
                  </TypographyDX>
                  <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                    {moment(quotationData.dueDate).format(DATE_FORMAT)}
                  </TypographyDX>
                </BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{
                      justifyContent: "flex-end",
                      width: 150,
                      backgroundColor: "#dddddd",
                    }}
                  >
                    Due Amount:
                  </TypographyDX>
                  <TypographyDX
                    variant="p"
                    sx={{
                      textAlign: "left",
                      backgroundColor: "#dddddd",
                    }}
                  >
                    {numberWithCommas(quotationData.netAmount?.toFixed(2))} SAR
                  </TypographyDX>
                </BoxDX>
              </BoxDX>
            </BoxDX>

            <DataGrid
              sx={{
                mt: 2,
                border: "none",
                outline: "none",
                boxShadow: "none",
                "& .MuiDataGrid-columnHeaders": {
                  border: "none",
                  outline: "none !important",
                },
                "& .MuiDataGrid-columnHeader": {
                  border: "none",
                  outline: "none !important",
                },
                "& .MuiDataGrid-cell": {
                  border: "none",
                  outline: "none !important",
                },
                "& .MuiDataGrid-row": {
                  backgroundColor: "transparent !important",
                },
                [`& .${gridClasses.columnSeparator}`]: {
                  [`&:not(.${gridClasses["columnSeparator--resizable"]})`]: {
                    display: "none",
                  },
                },
              }}
              rowHeight={20}
              onColumnHeaderClick={() => {}}
              hideFooter
              showCellVerticalBorder={false}
              disableColumnFilter={true}
              disableRowSelectionOnClick
              disableColumnSelector
              disableColumnMenu
              disableDensitySelector
              disableEval
              disableVirtualization
              columns={[
                {
                  field: "id",
                  headerName: "ID",
                  width: 90,
                  disableColumnMenu: true,
                  sortable: false,
                  resizable: false,
                },
                {
                  field: "description",
                  headerName: "Product",
                  flex: 1,
                  disableColumnMenu: true,
                  sortable: false,
                  resizable: false,
                },
                {
                  field: "quantity",
                  headerName: "Qty",
                  width: 90,
                  disableColumnMenu: true,
                  sortable: false,

                  resizable: false,
                },
                {
                  field: "unitPrice",
                  headerName: "Unit Price",
                  width: 150,
                  disableColumnMenu: true,
                  sortable: false,

                  resizable: false,
                },
                {
                  field: "discountAmount",
                  headerName: "Dis.",
                  width: 150,
                  disableColumnMenu: true,
                  sortable: false,

                  resizable: false,
                },
                {
                  field: "totalAmount",
                  headerName: "Total Before VAT",
                  width: 150,

                  resizable: false,
                },
              ]}
              rows={quotationData.quotationItems?.map(
                (item: any, index: number) => ({
                  id: index + 1,
                  description: item.description,
                  quantity: item.quantity,
                  unitPrice: `${numberWithCommas(
                    item.unitPrice?.toFixed(2)
                  )} SAR `,
                  discountAmount: numberWithCommas(
                    item.discountAmount?.toFixed(2)
                  ),
                  totalAmount: numberWithCommas(item.totalAmount?.toFixed(2)),
                })
              )}
            />

            <BoxDX
              sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
            >
              <BoxDX></BoxDX>
              <BoxDX>
                <BoxDX>
                  <BoxDX sx={{ display: "flex", gap: 1 }}>
                    <TypographyDX
                      variant="p"
                      fontWeight="bold"
                      sx={{
                        justifyContent: "flex-end",
                        width: 150,
                      }}
                    >
                      Total Before VAT:
                    </TypographyDX>
                    <TypographyDX
                      variant="p"
                      fontWeight="bold"
                      sx={{ textAlign: "left" }}
                    >
                      {numberWithCommas(quotationData.grossAmount?.toFixed(2))}{" "}
                      SAR
                    </TypographyDX>
                  </BoxDX>
                  <BoxDX sx={{ display: "flex", gap: 1 }}>
                    <TypographyDX
                      variant="p"
                      fontWeight="bold"
                      sx={{ justifyContent: "flex-end", width: 150 }}
                    >
                      Total VAT:
                    </TypographyDX>
                    <TypographyDX
                      variant="p"
                      fontWeight="bold"
                      sx={{ textAlign: "left" }}
                    >
                      {numberWithCommas(quotationData.vatAmount?.toFixed(2))}
                      SAR
                    </TypographyDX>
                  </BoxDX>
                  <BoxDX sx={{ display: "flex", gap: 1 }}>
                    <TypographyDX
                      variant="p"
                      fontWeight="bold"
                      sx={{ justifyContent: "flex-end", width: 150 }}
                    >
                      Total:
                    </TypographyDX>
                    <TypographyDX
                      variant="p"
                      fontWeight="bold"
                      sx={{ textAlign: "left" }}
                    >
                      {numberWithCommas(quotationData.netAmount?.toFixed(2))}{" "}
                      SAR
                    </TypographyDX>
                  </BoxDX>
                </BoxDX>
              </BoxDX>
            </BoxDX>
            <BoxDX dir="rtl" sx={{ mt: 3, fontFamily: "Almarai" }}>
              <TypographyDX
                variant="h5"
                fontWeight="bold"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_heading")}
              </TypographyDX>
              <TypographyDX
                variant="h6"
                fontWeight="bold"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_heading_1")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_content_1_1")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_1_2")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_1_3")}
              </TypographyDX>
              <TypographyDX
                variant="h6"
                fontWeight="bold"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_heading_2")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_content_2_1")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_2_2")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_2_3")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_2_4")}
              </TypographyDX>
              <TypographyDX
                variant="h6"
                fontWeight="bold"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_heading_3")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_content_3_1")}
              </TypographyDX>
              <TypographyDX
                variant="h6"
                fontWeight="bold"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_heading_4")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_content_4_1")}
              </TypographyDX>
              <TypographyDX
                variant="h6"
                fontWeight="bold"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_heading_5")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_content_5_1")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_5_2")}
              </TypographyDX>
              <TypographyDX
                variant="h6"
                fontWeight="bold"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_heading_6")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai", mt: 1 }}
              >
                {t("pdf_content_6_1")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_6_2")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_6_3")}
              </TypographyDX>
              <TypographyDX
                variant="body2"
                sx={{ direction: "rtl", fontFamily: "Almarai" }}
              >
                {t("pdf_content_6_4")}
              </TypographyDX>
            </BoxDX>

            <Table sx={{ minWidth: 650, mt: 5 }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ border: 1, textAlign: "center" }}
                  >
                    {t("Bank Account Information")} -
                    <span style={{ fontFamily: "Almarai" }}>
                      معلومات الحساب البنكي
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: 1, textAlign: "center" }}>
                    1234567899090
                  </TableCell>
                  <TableCell sx={{ border: 1 }}>
                    <TypographyDX
                      sx={{ justifyContent: "center", fontFamily: "Almarai" }}
                    >
                      رقم حساب
                    </TypographyDX>
                    <TypographyDX sx={{ justifyContent: "center" }}>
                      Account Number
                    </TypographyDX>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 1,
                      textAlign: "center",
                      fontFamily: "Almarai",
                    }}
                  >
                    بنك الحساب الذي سيتم إيداع الأموال فيه
                  </TableCell>
                  <TableCell sx={{ border: 1 }}>
                    <TypographyDX
                      sx={{ justifyContent: "center", fontFamily: "Almarai" }}
                    >
                      أسم المستفيد
                    </TypographyDX>
                    <TypographyDX sx={{ justifyContent: "center" }}>
                      Beneficiary Name
                    </TypographyDX>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: 1, textAlign: "center" }}>
                    324324324324324234
                  </TableCell>
                  <TableCell sx={{ border: 1 }}>
                    <TypographyDX
                      sx={{ justifyContent: "center", fontFamily: "Almarai" }}
                    >
                      رقم الحساب المصرفي الدولي
                    </TypographyDX>
                    <TypographyDX sx={{ justifyContent: "center" }}>
                      IBAN
                    </TypographyDX>
                  </TableCell>
                  <TableCell sx={{ border: 1 }}>
                    <TypographyDX
                      sx={{ justifyContent: "center", fontFamily: "Almarai" }}
                    >
                      بنك الراجحي
                    </TypographyDX>
                    <TypographyDX sx={{ justifyContent: "center" }}>
                      Rajhi bank
                    </TypographyDX>
                  </TableCell>
                  <TableCell sx={{ border: 1 }}>
                    <TypographyDX
                      sx={{ justifyContent: "center", fontFamily: "Almarai" }}
                    >
                      بنك
                    </TypographyDX>
                    <TypographyDX sx={{ justifyContent: "center" }}>
                      Bank
                    </TypographyDX>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    )
  );
};

export default QuotationInvoiceReport;
