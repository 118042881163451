import { useEffect, useState } from "react";
import { t } from "i18next";

import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import { useNotificationContext } from "../../../context/notificationcontext";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";
import { DATE_FORMAT } from "../../../shared/globals";
import SelectListDX from "../../controls/selectlistdx";

const SubscriptionDetailsModal = (props: any) => {
  const {
    handleClose,
    setCustomerSubscriptions,
    customerSubscriptions,
    warehouseDropdownData,
    packageDropdownData,
    quotationDropdownData,
    packages,
    subscriptionId,
  } = props;
  const { setInfo, setError } = useNotificationContext();

  const defaultValues = {
    id: Math.floor(Math.random() * 1000),
    customerId: 0,
    warehouseId: null,
    quotationId: null,
    subscriptionPackageId: null,
    subscribedCbm: 0,
    startDate: null,
    endDate: null,
  };
  const [subscriptionData, setSubscriptionData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    let parsedValue = value;
    if (type === "number") {
      parsedValue = parseFloat(value); // Parse the value to a float
    }
    setSubscriptionData({
      ...subscriptionData,
      [name]: type == "checkbox" ? checked : parsedValue,
    });
  };
  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setSubscriptionData({
      ...subscriptionData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!subscriptionData.startDate) {
      newErrors["startDate"] = t("Start Date is required");
    }
    if (!subscriptionData.warehouseId) {
      newErrors["warehouseId"] = t("Warehouse is required");
    }
    if (!subscriptionData.subscriptionPackageId) {
      newErrors["subscriptionPackageId"] = t(
        "Subscription Package is required"
      );
    }
    if (!subscriptionData.quotationId) {
      newErrors["quotationId"] = t("Quotation is required");
    }
    if (!subscriptionData.subscribedCbm) {
      newErrors["subscribedCbm"] = t("Subscribed CBM is required");
    }

    // validate if same subscription package is already added in the same warehouse
    const existingSubscription = customerSubscriptions.find(
      (sub: any) =>
        sub.subscriptionPackageId === subscriptionData.subscriptionPackageId &&
        sub.warehouseId === subscriptionData.warehouseId
    );
    if (existingSubscription) {
      newErrors["subscriptionPackageId"] = t(
        "This subscription package is already added in this warehouse"
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getData = () => {
    if (subscriptionId) {
      setIsLoading(true);
      const subscription = customerSubscriptions.find(
        (sub: any) =>
           sub.id === subscriptionId
      );
      setSubscriptionData(subscription);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const onSave = () => {
    if (validateForm()) {
      if (!isChanged) return handleClose(false);
      setIsLoading(true);

      if (subscriptionId) {
        // Update the data
        const updatedSubscriptions = customerSubscriptions.map((sub: any) =>
          sub.id === subscriptionId
            ? subscriptionData
            : sub
        );
        setCustomerSubscriptions(updatedSubscriptions);
      } else {
        // Save the data
        const { endDate, ...rest } = subscriptionData;
        // dont send end date to the server
        setCustomerSubscriptions((prev: any) => [...prev, { ...rest }]);
      }
      setIsLoading(false);
      handleClose();
    }
  };
  // whenever the pakaage changes, update the end date according to package duration
  useEffect(() => {
    if (
      subscriptionData?.subscriptionPackageId &&
      subscriptionData?.startDate
    ) {
      const selectedPackage = packages.find(
        (pkg: any) =>
          pkg.subscriptionPackageId === subscriptionData.subscriptionPackageId
      );
      if (selectedPackage) {
        const endDate = moment(subscriptionData.startDate).add(
          selectedPackage.packageDays,
          "days"
        );
        setSubscriptionData({
          ...subscriptionData,
          endDate: endDate.format(DATE_FORMAT),
        });
      }
    }
  }, [subscriptionData?.subscriptionPackageId, subscriptionData?.startDate]);

  return (
    <AddEditModalDX
      open={true}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Subscription Details")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container rowSpacing={2} columnSpacing={1} sx={{ pt: 1 }}>
        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Subscription Package")}
            name="subscriptionPackageId"
            value={subscriptionData.subscriptionPackageId}
            onChange={handleInputChange}
            error={errors["subscriptionPackageId"]}
            items={packageDropdownData}
            InputLabelProps={{
              shrink: subscriptionData.subscriptionPackageId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Warehouse")}
            name="warehouseId"
            value={subscriptionData.warehouseId}
            onChange={handleInputChange}
            error={errors["warehouseId"]}
            items={warehouseDropdownData}
            InputLabelProps={{
              shrink: subscriptionData.warehouseId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <UnitsDatePicker
            label={t("Start Date")}
            name="startDate"
            value={moment(subscriptionData.startDate)}
            onChange={(value: any) => handleDateChange(value, "startDate")}
            error={errors["startDate"]}
            minDate={moment()}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <UnitsDatePicker
            disabled
            label={t("End Date")}
            name="endDate"
            value={moment(subscriptionData.endDate)}
            onChange={(value: any) => handleDateChange(value, "endDate")}
            error={errors["endDate"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("Quotation #")}
            name="quotationId"
            value={subscriptionData.quotationId}
            onChange={handleInputChange}
            error={errors["quotationId"]}
            items={quotationDropdownData}
            InputLabelProps={{
              shrink: subscriptionData.quotationId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Subscribed CBM")}
            name="subscribedCbm"
            value={subscriptionData.subscribedCbm}
            onChange={handleInputChange}
            error={errors["subscribedCbm"]}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default SubscriptionDetailsModal;
