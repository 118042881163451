import { useEffect, useState } from "react";
import { t } from "i18next";

import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import useWarehouseLevelService from "../../../shared/services/warehouselevelsservice";
import { useNotificationContext } from "../../../context/notificationcontext";

const WarehouseLevelDetailsModal = (props: any) => {
  const {
    handleClose,
    warehouseBayId,
    warehouseLevelId,
    totalSpaceInBay,
    allotedSpaceForLevels,
  } = props;
  const { addWarehouseLevel, updateWarehouseLevel, getWarehouseLevelById } =
    useWarehouseLevelService();
  const { setInfo, setError } = useNotificationContext();
  const defaultValues = {
    warehouseBayId: warehouseBayId,
    warehouseLevelId: 0,
    number: "",
    capacity: null,
    palletSize: null,
    numberOfPallets: null,
  };
  const [levelData, setLevelData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [previouslyAllotedSpace, setPreviouslyAllotedSpace] = useState<any>(0);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    let parsedValue = value;
    if (type === "number") {
      parsedValue = parseFloat(value); // Parse the value to a float
    }
    setLevelData({
      ...levelData,
      [name]: type == "checkbox" ? checked : parsedValue,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!levelData.number || levelData.number <= 0) {
      newErrors["number"] = t("Number is required and must be greater than 0");
    }
    if (!levelData.capacity || levelData.capacity <= 0) {
      newErrors["capacity"] = t(
        "Capacity is required and must be greater than 0"
      );
    }
    if (!levelData.palletSize || levelData.palletSize <= 0) {
      newErrors["palletSize"] = t(
        "Pallet Size is required and must be greater than 0"
      );
    }
    if (!levelData.numberOfPallets || levelData.numberOfPallets <= 0) {
      newErrors["numberOfPallets"] = t(
        "Number Of Pallets is required and must be greater than 0"
      );
    }

    if (warehouseLevelId) {
      if (
        totalSpaceInBay &&
        allotedSpaceForLevels &&
        totalSpaceInBay -
          (allotedSpaceForLevels - parseFloat(previouslyAllotedSpace)) <
          parseFloat(levelData.capacity)
      ) {
        newErrors["capacity"] = t(
          `Storage Capacity cannot be more than the remaining space(${
            totalSpaceInBay -
            (allotedSpaceForLevels - parseFloat(previouslyAllotedSpace))
          }) in the Level.`
        );
      }
    } else if (
      totalSpaceInBay &&
      allotedSpaceForLevels &&
      totalSpaceInBay - allotedSpaceForLevels < parseFloat(levelData.capacity)
    ) {
      newErrors["capacity"] = t(
        `Storage Capacity cannot be more than the remaining space(${
          totalSpaceInBay - allotedSpaceForLevels
        }) in the Level.`
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getData = () => {
    if (warehouseLevelId) {
      setIsLoading(true);
      getWarehouseLevelById(warehouseLevelId)
        .then((level) => {
          setPreviouslyAllotedSpace(level.capacity);
          setLevelData(level);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const onSave = () => {
    if (!isChanged) return handleClose(false);
    if (validateForm()) {
      setIsLoading(true);
      const operation = warehouseLevelId
        ? updateWarehouseLevel(levelData)
        : addWarehouseLevel(levelData);
      operation
        .then(() => {
          warehouseLevelId
            ? setInfo(t("Warehouse Level updated successfully"))
            : setInfo(t("Warehouse Level added successfully"));
          handleClose(true);
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <AddEditModalDX
      open={true}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Warehouse Level")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Number")}
            name="number"
            value={levelData?.number}
            onChange={handleInputChange}
            error={errors["number"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Capacity")}
            name="capacity"
            value={levelData?.capacity}
            onChange={handleInputChange}
            error={errors["capacity"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Pallet Size")}
            name="palletSize"
            value={levelData?.palletSize}
            onChange={handleInputChange}
            error={errors["palletSize"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Number Of Pallets")}
            name="numberOfPallets"
            value={levelData?.numberOfPallets}
            onChange={handleInputChange}
            error={errors["numberOfPallets"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default WarehouseLevelDetailsModal;
